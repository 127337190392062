import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75a16258"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "add-button" }
const _hoisted_4 = { class: "total-containers" }
const _hoisted_5 = { class: "total-containers-short" }
const _hoisted_6 = { class: "progress-container-ft" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_safety_inspection_questionnaire = _resolveComponent("safety-inspection-questionnaire")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_add_transaction_modal = _resolveComponent("add-transaction-modal")!
  const _component_shipping_truck = _resolveComponent("shipping-truck")!
  const _component_shipping_header = _resolveComponent("shipping-header")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_transaction_line_table = _resolveComponent("transaction-line-table")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_progress_bar = _resolveComponent("b-progress-bar")!
  const _component_shipping_closeout_modal = _resolveComponent("shipping-closeout-modal")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.state.showEquipmentInspection)
      ? (_openBlock(), _createBlock(_component_safety_inspection_questionnaire, {
          key: 0,
          modelValue: _ctx.state.showPreLoadSafetyInspection,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showPreLoadSafetyInspection) = $event)),
          carriers: _ctx.state.carriers,
          "inspection-type": _ctx.SafetyInspectionType.PreLoad,
          shipment: _ctx.shippingStore.shipment,
          "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
          "modify-carrier-and-trailer": "",
          onCanceled: _ctx.cancelSafetyInspection,
          onRejected: _ctx.cancelSafetyInspection,
          onPassed: _ctx.passedPreloadSafetyInspection
        }, null, 8, ["modelValue", "carriers", "inspection-type", "shipment", "inspection-title", "onCanceled", "onRejected", "onPassed"]))
      : _createCommentVNode("", true),
    (!_ctx.state.showEquipmentInspection)
      ? (_openBlock(), _createBlock(_component_safety_inspection_questionnaire, {
          key: 1,
          modelValue: _ctx.state.showPostLoadSafetyInspection,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showPostLoadSafetyInspection) = $event)),
          carriers: _ctx.state.carriers,
          "inspection-type": _ctx.SafetyInspectionType.PostLoad,
          shipment: _ctx.shippingStore.shipment,
          "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
          onPassed: _ctx.resetPostloadSafetyInspection,
          onRejected: _ctx.resetPostloadSafetyInspection
        }, null, 8, ["modelValue", "carriers", "inspection-type", "shipment", "inspection-title", "onPassed", "onRejected"]))
      : _createCommentVNode("", true),
    (!_ctx.state.showEquipmentInspection)
      ? (_openBlock(), _createBlock(_component_safety_inspection_questionnaire, {
          key: 2,
          modelValue: _ctx.state.showSafetyChecklist,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.showSafetyChecklist) = $event)),
          carriers: _ctx.state.carriers,
          "inspection-type": _ctx.SafetyInspectionType.SafetyOnly,
          shipment: _ctx.shippingStore.shipment,
          "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
          "modify-carrier-and-trailer": "",
          onCanceled: _ctx.cancelSafetyInspection,
          onRejected: _ctx.cancelSafetyInspection
        }, null, 8, ["modelValue", "carriers", "inspection-type", "shipment", "inspection-title", "onCanceled", "onRejected"]))
      : _createCommentVNode("", true),
    (!_ctx.state.loading)
      ? (_openBlock(), _createBlock(_component_safety_inspection_questionnaire, {
          key: 3,
          modelValue: _ctx.state.showEquipmentInspection,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.showEquipmentInspection) = $event)),
          "inspection-type": _ctx.SafetyInspectionType.EquipmentInspection,
          "inspection-title": _ctx.getTitleCaseTranslation('core.common.equipmentSafetyInspection'),
          onCanceled: _ctx.cancelSafetyInspection,
          onRejected: _ctx.cancelSafetyInspection
        }, null, 8, ["modelValue", "inspection-type", "inspection-title", "onCanceled", "onRejected"]))
      : _createCommentVNode("", true),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showAddItemModal,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.showAddItemModal) = $event)),
      centered: "",
      "hide-footer": "",
      title: _ctx.getTitleCaseTranslation('core.button.addItem')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_item_picker, {
          placeholder: _ctx.getTranslation('core.common.typeToSearchForItem'),
          "allow-quantity-change": "",
          "item-type": _ctx.ItemType.SHIPPING,
          shipping: { fromLocationId: _ctx.shippingStore.shipment.fromLocation.id, toLocationId: _ctx.shippingStore.activeTransaction.toLocation?.id },
          onOnSelect: _ctx.addItem
        }, null, 8, ["placeholder", "item-type", "shipping", "onOnSelect"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_add_transaction_modal, {
      ref: "addTransactionModalRef",
      onAddNewTransaction: _ctx.addNewTransaction,
      onAddExistingTransaction: _ctx.addExistingTransaction
    }, null, 8, ["onAddNewTransaction", "onAddExistingTransaction"]),
    _createVNode(_component_screen, {
      class: "details-container",
      title: _ctx.shippingHeaderTitle,
      full: "",
      loading: !_ctx.state.show
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_smart_trak_footer, {
          "content-direction": "space-between",
          style: {"height":"80px"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.shippingStore.activeTransaction?.totalItems || 0) + " " + _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.totalItems')), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.shippingStore.activeTransaction?.totalItems || 0) + " " + _toDisplayString(_ctx.getTitleCaseTranslation('core.common.total')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_b_progress_bar, {
                percent: _ctx.maxTrailerComposable?.volumePercent,
                message: _ctx.maxTrailerComposable?.percentVolumeUsed,
                "overflow-message": _ctx.maxTrailerComposable?.overflowVolumeMessage
              }, null, 8, ["percent", "message", "overflow-message"]),
              _createVNode(_component_b_progress_bar, {
                percent: _ctx.maxTrailerComposable?.weightPercent,
                message: _ctx.maxTrailerComposable?.percentWeightUsedMessage,
                "overflow-message": _ctx.maxTrailerComposable?.overflowWeightMessage
              }, null, 8, ["percent", "message", "overflow-message"])
            ]),
            _createVNode(_component_shipping_closeout_modal, {
              ref: "shippingModalRef",
              style: {"margin":"auto"},
              "shipment-is-readonly": _ctx.shippingStore.readonly,
              "can-ship-now": _ctx.shippingStore.canShipNow,
              "max-trailer-composable": _ctx.maxTrailerComposable,
              "trailer-closed": _ctx.trailerClosed,
              "save-and-validate": _ctx.saveBeforeAction,
              "is-over-or-short-shipped": _ctx.isOverOrShortShipped,
              onSaveShipment: _ctx.save,
              onCloseTrailer: _ctx.closeTrailer,
              onMoveToYard: _ctx.moveToYard,
              onShipNow: _ctx.shipNow
            }, null, 8, ["shipment-is-readonly", "can-ship-now", "max-trailer-composable", "trailer-closed", "save-and-validate", "is-over-or-short-shipped", "onSaveShipment", "onCloseTrailer", "onMoveToYard", "onShipNow"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_row, { class: "details-content" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, { class: "carrier-details" }, {
              default: _withCtx(() => [
                _createElementVNode("h6", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.carrierDetails')), 1),
                _createVNode(_component_shipping_truck, {
                  "shipping-store": _ctx.shippingStore,
                  readonly: _ctx.shippingStore.readonly,
                  "validation-result": _ctx.state.validationResult,
                  onChangeTransaction: _ctx.changeTransaction,
                  onAddTransaction: _ctx.openAddTransactionModal
                }, null, 8, ["shipping-store", "readonly", "validation-result", "onChangeTransaction", "onAddTransaction"]),
                _createVNode(_component_shipping_header, {
                  key: _ctx.shippingStore.activeIndex,
                  modelValue: _ctx.shippingStore.activeTransaction,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shippingStore.activeTransaction) = $event)),
                  modelShipment: _ctx.shippingStore.shipment,
                  "onUpdate:modelShipment": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.shippingStore.shipment) = $event)),
                  "shipment-is-readonly": _ctx.shippingStore.readonly,
                  "validation-result": _ctx.state.validationResult,
                  "active-index": _ctx.shippingStore.activeIndex,
                  carriers: _ctx.state.carriers
                }, null, 8, ["modelValue", "modelShipment", "shipment-is-readonly", "validation-result", "active-index", "carriers"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, { class: "container-details" }, {
              default: _withCtx(() => [
                (_ctx.transactionLineErrors)
                  ? (_openBlock(), _createElementBlock("h6", _hoisted_1, _toDisplayString(_ctx.state.validationResult?.model.transactions[_ctx.shippingStore.activeIndex]?.transactionLines), 1))
                  : (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.containerDetails')), 1)),
                (_ctx.shippingStore.activeTransaction?.transactionLines.length > 0)
                  ? (_openBlock(), _createBlock(_component_transaction_line_table, {
                      key: 2,
                      "line-list": _ctx.shippingStore.activeTransaction.transactionLines,
                      "table-type": "shipping",
                      readonly: _ctx.shippingStore.readonly,
                      manual: _ctx.canManuallyKeyInTags
                    }, null, 8, ["line-list", "readonly", "manual"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_b_button, {
                      disabled: _ctx.shippingStore.readonly,
                      onClick: _ctx.openAddItemModal
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addItems')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title", "loading"])
  ], 64))
}
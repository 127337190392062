
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Shipment from '@/domain/Shipment';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import { ItemType } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import ReceivingService from '@/services/ReceivingService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import useLoading from '@/modules/floortrak/composables/useLoading';
import useReceivingSearch from '@/modules/floortrak/composables/useReceivingSearch';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import InventoryService from '@/services/InventoryService';
import TotalQuantityInventoryAdjustmentDTO from '@/dtos/TotalQuantityInventoryAdjustmentDTO';
import InventoryCategory from '@/domain/InventoryCategory';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import SortedItemService from '@/services/SortedItemService';
import SortedItem from '@/domain/SortedItem';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionService from '@/services/SafetyInspectionService';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import { CoreStore } from '@/store/CoreStore';
import { useNotification } from '@/composable/useNotifications';

interface ItemTableData {
    item: Item;
    name: string;
    estimatedQuantity: number;
    actualQuantity: number;
    remainingQuantity: number;
    imageUrlThumb: string | undefined;
    imageUrlFull: string | undefined;
}

interface State {
    loading: boolean;
    search: number | null;
    shipment: Shipment;
    showSearch: boolean;
    searching: boolean;
    inventoryAdjustments: TotalQuantityInventoryAdjustmentDTO[];
    sortedItems: Array<SortedItem>;
    showPostUnloadSafetyInspection: boolean;
    unloadComplete: boolean;
}

export default defineComponent({
    name: 'closeout',
    components: {
        Thumbnail,
        BSpinner,
        BFormInput,
        BTable,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        SafetyInspectionQuestionnaire,
    },
    props: {
        transactionNumber: {
            type: String,
            required: false,
            default: undefined,
        }, // this is a route param, it has to be a string => convert to number onSearch
    },
    setup(props) {
        const receivingService = new ReceivingService();
        const inventoryService = new InventoryService();
        const sortedItemService = new SortedItemService();
        const safetyInspectionService = new SafetyInspectionService();

        const { confirm } = useDialogBox();        
        const { carrierStore } = CoreStore.getInstance();
        const { userLocation } = CoreStore.getInstance().profileStore;

        const state = reactive<State>({
            loading: false,
            search: null,
            shipment: new Shipment(),
            showSearch: true,
            searching: false,
            inventoryAdjustments: [],
            sortedItems: [],
            showPostUnloadSafetyInspection: false,
            unloadComplete: false,
        });

        let loadingTasks = 0;

        function setLoading(loading: boolean) {
            if (loading) {
                loadingTasks++;
                state.loading = true;
            } else {
                loadingTasks--;
                if (loadingTasks <= 0) {
                    state.loading = false;
                }
            }
        }

        function closeSearch() {
            state.search = null;
            state.showSearch = false;
        }

        function hasTransactionLines(curTransaction: Transaction): boolean {
            return curTransaction.transactionLines.length > 0;
        }

        async function initExisting(shipment: Shipment) {
            state.shipment = shipment;

            const sortedItemResult = await sortedItemService.getSortedItemsByTransaction(shipment.transactions[0].id);
            if (sortedItemResult.success) {
                state.sortedItems = sortedItemResult.sortedItems;
            }

            if (hasTransactionLines(shipment.transactions[0])) {
                const response = await inventoryService.getTotalPutAwayInventoryAdjustments(shipment.transactions[0].id);
                if (response) {
                    state.inventoryAdjustments = response;
                }
            }

            state.unloadComplete = await safetyInspectionService.trailerInspectionOfTypePassesForShipment(state.shipment.id!, SafetyInspectionType.PostUnload);
        }

        async function postSearchSuccess(shipment: Shipment) {
            await initExisting(shipment);
            closeSearch();
        }

        const { fetchReceivingShipment } = useReceivingSearch(postSearchSuccess);

        async function searchExisting() {
            if (state.search) {
                state.searching = true;
                await fetchReceivingShipment(state.search, ReceivingAction.CLOSEOUT);
                state.searching = false;
            }
        }

        onBeforeMount(async () => {
            setLoading(true);
            state.shipment.addTransaction(new Transaction());
            state.shipment.transactions[0].toLocation = userLocation;

            if (props.transactionNumber) {
                state.showSearch = false;
                state.search = parseInt(props.transactionNumber, 10);
                await searchExisting();
                if (!state.shipment?.id) {
                    state.showSearch = true;
                }
            }
            setLoading(false);
        });

        const transaction = computed(
            (): Transaction => {
                if (state.shipment.transactions.length > 0) {
                    return state.shipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );

        const distinctInventoryAdjustmentCategories = computed(
            (): Array<InventoryCategory> => {
                const categories: InventoryCategory[] = [];
                state.inventoryAdjustments.forEach((inventoryAdjustment) => {
                    if (!categories.some((x) => x.id === inventoryAdjustment.inventoryCategory.id)) {
                        categories.push(inventoryAdjustment.inventoryCategory);
                    }
                });

                return categories.sort((a, b) => b.id - a.id);
            },
        );

        function mapTransLinesToTableData(lines: Array<TransactionLine>): Array<ItemTableData> {
            return lines.map((line) => {
                const data = {
                    item: line.item,
                    name: line.item.name,
                    estimatedQuantity: line.estimatedQuantity,
                    actualQuantity: line.actualQuantity,
                    remainingQuantity: line.estimatedQuantity - line.actualQuantity,
                    imageUrlFull: line.item.imageUrlFull,
                    imageUrlThumb: line.item.imageUrlThumb,
                };

                state.inventoryAdjustments
                    .filter((x) => x.itemId === line.item.id)
                    .forEach((inventoryAdjustment) => {
                        // @ts-ignore
                        data[inventoryAdjustment.inventoryCategory.toKey()] = inventoryAdjustment.quantity;
                    });

                return data;
            });
        }

        const tableData = computed(
            (): Array<ItemTableData> => {
                if (transaction.value && transaction.value.transactionLines) {
                    return mapTransLinesToTableData(transaction.value.transactionLines as Array<TransactionLine>);
                }
                return [];
            },
        );

        const fields = computed(
            (): Array<BTableField<ItemTableData & { action: string }>> => {
                const list: Array<BTableField<ItemTableData & { action: string }>> = [
                    {
                        key: 'imageUrlThumb',
                        label: getTitleCaseTranslation('core.common.image'),
                        width: '70px',
                        ignoreSort: true,
                    },
                    { key: 'name', label: getTitleCaseTranslation('core.domain.name') },
                    { key: 'estimatedQuantity', label: getTitleCaseTranslation('core.common.estimatedQuantityShort') },
                    { key: 'actualQuantity', label: getTitleCaseTranslation('core.common.actualQuantityShort') },
                ];

                list.push(
                    ...distinctInventoryAdjustmentCategories.value.map((x): BTableField<ItemTableData & { action: string }> => ({ key: x.toKey() as keyof ItemTableData, label: x.description })),
                );

                list.push({ key: 'remainingQuantity', label: getTitleCaseTranslation('core.common.remainingQuantityShort') });
                return list;
            },
        );

        async function submit() {
            setLoading(true);
            if (!state.unloadComplete) {
                state.showPostUnloadSafetyInspection = true;
                useNotification().showSuccess();
                return;
            }
            setLoading(false);

            let confirmResponse = true;
            if (hasTransactionLines(transaction.value)) {
                // warn user if there are items with estimates but no actuals
                const estimatedItems = transaction.value.transactionLines.filter((x) => x.estimatedQuantity > 0);
                if (estimatedItems.length && transaction.value.totalItems === 0) {
                    const list = estimatedItems.map((x) => `<li>${[x.item.customerItemNumber, x.item.name].join(' | ')}</li>`);
                    confirmResponse = await confirm({
                        title: getTranslation('core.common.areYouSure'),
                        message: `${getTranslation('core.validation.transactionContainsEstimatesNoActuals')} <br/><br/><ul>${list.join('')}</ul>`,
                        vHtml: true,
                    });
                }

                if (!confirmResponse) {
                    return;
                }
            } else {
                confirmResponse = await confirm({
                    title: getTranslation('core.common.areYouSure'),
                    message: getTranslation('core.validation.receiptHasNoLineItemsAreYouSure'),
                });

                if (!confirmResponse) {
                    await router.push({ name: FloorTrakRouteTypes.HOME });
                    useNotification().showSuccess();
                    return;
                }
            }

            // warn user if there are sorted items that are not load complete
            if (hasTransactionLines(transaction.value)) {
                const incompleteItems = state.sortedItems.filter((x) => !x.completed && (transaction.value.getTransactionLineByItemId(x.itemId)?.estimatedQuantity ?? 0) > 0);
                if (incompleteItems.length) {
                    useNotification().showError(`${getTranslation('core.common.itemsStillRequireLoadComplete')}`);
                    return;
                }
            }

            setLoading(true);
            const response = await receivingService.submitCloseout(transaction.value.id);
            if (response) {
                await router.push({ name: FloorTrakRouteTypes.HOME });
            }
            useNotification().showSuccess();
            setLoading(false);
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        async function goBack() {
            if (transaction.value?.id) {
                await router.push({
                    name: FloorTrakRouteTypes.RECEIVING.PUT_AWAY,
                    params: { transactionNumber: transaction.value.id },
                });
            } else {
                await router.push({ name: FloorTrakRouteTypes.HOME });
            }
        }

        async function unloadComplete(inspection: SafetyInspection) {
            setLoading(true);
            state.unloadComplete = await safetyInspectionService.associateShipmentWithTrailerInspection(state.shipment.id!, inspection.id);
            if (state.unloadComplete) {
                state.showPostUnloadSafetyInspection = false;
                submit();
            }

            useNotification().showSuccess();
            setLoading(false);
        }

        async function cancelPostUnloadSafetyInspection() {
            await router.push({ name: FloorTrakRouteTypes.HOME });
        }

        return {
            state,
            transaction,
            fields,
            ItemType,
            tableData,
            searchExisting,
            submit,
            escape,
            goBack,
            getTranslation,
            getTitleCaseTranslation,
            carrierStore,
            SafetyInspectionType,
            unloadComplete,
            cancelPostUnloadSafetyInspection,
        };
    },
});

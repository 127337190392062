import Location from '@/domain/Location';
import TransactionAbstractShare from '@/domain/TransactionAbstractShare';
import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import { normalizeDate } from '@/functions/date';

// Subset of the TransactionDTO used where performance is key
export default class TransactionDigest extends TransactionAbstractShare {
    public id: number = 0;

    public toLocationId!: number;

    public toLocationName!: string;

    public fromLocationId!: number;

    public fromLocationName!: string;

    public transactionStatusDescription!: string;

    public createdOn!: Date;

    public plannedDepartureDate!: Date;

    public actualDepartureDate!: Date;

    public plannedDeliveryDate!: Date;

    public dateRequired!: Date;

    public arrivedAt?: Date;

    public status!: TransactionStatus;

    public carrier!: string;

    public carrierReferenceNumber!: string;

    public partnerReferenceNumber!: string;

    public sealNumber!: string;

    public customerPO!: string;

    public trailer!: string;

    public isShippingToDifferentCountry!: boolean;

    public licensePlateNumber!: string;

    public originalTransactionId?: number;

    constructor(init?: TransactionDigest) {
        super();
        Object.assign(this, init);
        if (init) {
            this.createdOn = normalizeDate(init?.createdOn);
            this.plannedDepartureDate = normalizeDate(init.plannedDepartureDate);
            this.actualDepartureDate = normalizeDate(init.actualDepartureDate);
            this.plannedDeliveryDate = normalizeDate(init.plannedDeliveryDate);
            this.dateRequired = normalizeDate(init.dateRequired);
            this.transactionStatusDescription = Formatter.GetFriendlyValue(init.status);
            if (init.arrivedAt) {
                const arrivedAt = normalizeDate(init.arrivedAt);
                // arrivedAt defaults to '0001-01-01 00:00:00.0000000' instead of null in the DB. so make sure the date is valid
                this.arrivedAt = arrivedAt > new Date(1, 1, 1) ? arrivedAt : undefined;
            }
        } else {
            this.status = TransactionStatus.ORDERED;
        }
    }

    get hasShipped(): boolean {
        return this.status >= TransactionStatus.IN_TRANSIT;
    }

    get shipDate(): Date | null {
        if (this.status >= TransactionStatus.IN_TRANSIT) {
            return this.actualDepartureDate;
        }
        return this.plannedDepartureDate;
    }

    public canCancel(userLocation: Location): boolean {
        return this.status < TransactionStatus.HELD && this.getDirection(userLocation) === 'outbound';
    }

    public canDelete(userLocation: Location): boolean {
        return (
            (this.status === TransactionStatus.HELD
                || this.status === TransactionStatus.CONFIRMED
                || this.status === TransactionStatus.DELIVERY_CONFIRMED
                || this.status === TransactionStatus.IN_TRANSIT)
            && this.getDirection(userLocation) === 'outbound'
        );
    }
}


import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, reactive,
} from 'vue';
import Shipment from '@/domain/Shipment';
import TagListener from '@/modules/floortrak/services/TagListener';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Location from '@/domain/Location';
import Transaction from '@/domain/Transaction';
import { ItemType } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import ReceivingService from '@/services/ReceivingService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import CoreStore from '@/store/CoreStore';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import TrackedItem from '@/domain/TrackedItem';
import FloortrakReceivingTagScanHandler from '@/services/tag-scanning/scan-handler/FloortrakReceivingTagScanHandler';
import useReceivingSearch from '@/modules/floortrak/composables/useReceivingSearch';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import WarehousePutAwayDTO from '@/dtos/WarehousePutAwayDTO';
import FloorLocationService from '@/services/FloorLocationService';
import FloorLocation from '@/domain/FloorLocation';
import ItemService from '@/services/ItemService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import { useNotification } from '@/composable/useNotifications';
import TrackedItemService from '@/services/tag-scanning/TrackedItemService';
import TransactionService from '@/services/TransactionService';

type State = {
    loading: boolean;
    search: number | null;
    shipment: Shipment;
    showSearch: boolean;
    searching: boolean;
    putAwayItems: Array<WarehousePutAwayDTO>;
    tags: Array<TrackedItem>;
    showPrintLabelsModal: boolean;
    labelQuantity: number | undefined;
    printingLabels: boolean;
    showEquipmentInspection: boolean;
    selectedFloorLocation: FloorLocation | undefined;
    selectedPutAwayItems: Array<TrackedItem>;
    allFloorLocations: Array<FloorLocation>;
    transactionItems: Array<Item>;
    showLocationSearch: boolean;
    vacantFloorLocations: Array<FloorLocation>;
    sharedFloorLocations: Array<FloorLocation>;
    occupiedFloorLocations: Array<FloorLocation>;
    transactionLineItem: string | null;
};

interface ItemTableData {
    item: Item;
    floorLocation: string;
    putAwayQtys: number;
    scannedTags: Array<TrackedItem>;
    estimatedQty: number;
}

export default defineComponent({
    name: 'put-away',
    components: {
        BSpinner,
        BFormInput,
        BTable,
        TrackedItemTagModal,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        BModal,
        BRow,
        BCol,
        BButton,
        BForm,
        SafetyInspectionQuestionnaire,
        DropdownAutocompleteSingleSelect,
    },
    props: {
        transactionNumber: {
            type: String,
            required: false,
            default: undefined,
        }, // this is a route param, it has to be a string => convert to number onSearch
    },
    setup(props) {
        const receivingService = new ReceivingService();
        const { setLoading } = useLoading();
        const { forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;
        const { confirm } = useDialogBox();
        const floorLocationService = new FloorLocationService();
        const itemService = new ItemService();
        const trackedItemService = new TrackedItemService();
        const notification = useNotification();
        const transactionService = new TransactionService();

        const state = reactive<State>({
            loading: false,
            search: null,
            shipment: new Shipment(),
            showSearch: true,
            searching: false,
            putAwayItems: [],
            tags: [],
            labelQuantity: undefined,
            showPrintLabelsModal: false,
            printingLabels: false,
            showEquipmentInspection: true,
            selectedFloorLocation: undefined,
            selectedPutAwayItems: [],
            allFloorLocations: [],
            transactionItems: [],
            showLocationSearch: false,
            vacantFloorLocations: [],
            sharedFloorLocations: [],
            occupiedFloorLocations: [],
            transactionLineItem: null,
        });

        const tagListener = new TagListener(searchFloorLocationsAndItems);
        const transaction = computed(
            (): Transaction => {
                if (state.shipment.transactions.length > 0) {
                    return state.shipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );
        const tags = computed((): Array<TrackedItem> => state.tags as Array<TrackedItem>);

        const pageTitle = computed(() => getTitleCaseTranslation('core.common.warehousePutAway') + (transaction.value.id > 0 ? `: ${transaction.value.id}` : ''));

        function getCurrentTagsForItem(itemId: number, floorLocationId: number): Array<TrackedItem> {
            const currentTags: Array<TrackedItem> = [];
            const floorLocationsForItem = state.putAwayItems.filter((tag) => tag.itemId === itemId && tag.floorLocationId === floorLocationId);
            const tagsForItem = tags.value.filter((tag) => tag.itemId === itemId);

            floorLocationsForItem.forEach((x) => {
                const index = tagsForItem.findIndex((tag) => tag.id === x.trackedItemId);
                if (index >= 0) {
                    currentTags.push(tagsForItem[index]);
                }
            });

            return currentTags;
        }

        function getFloorLocationName(locationId: number): string {
            return state.allFloorLocations.find((fl) => fl.id === locationId)?.name ?? '';
        }

        async function getItemById(itemId: number) : Promise<Item> {
            const item = await itemService.getItemById(itemId);

            return item.item ?? new Item();
        }

        function mapTransLinesToTableData(lines: Array<ItemTableData>): Array<ItemTableData> {
            return lines.map((line) => ({
                item: line.item,
                floorLocation: line.floorLocation,
                putAwayQtys: line.putAwayQtys,
                estimatedQty: line.estimatedQty,
                scannedTags: line.scannedTags,
            }));
        }

        const putAwayItems = computed((): Array<WarehousePutAwayDTO> => state.putAwayItems as Array<WarehousePutAwayDTO>);

        const tableData = computed(
            (): Array<ItemTableData> => {
                if (state.putAwayItems) {
                    const sortedLines: Array<ItemTableData> = [];
                    putAwayItems.value.forEach((dto) => {
                        const foundItem = state.transactionItems.find((i) => i.id === dto.itemId)!;
                        const floorLocationName = getFloorLocationName(dto.floorLocationId);
                        const foundLine = sortedLines.findIndex((line) => line.item.displayName === foundItem.displayName && line.floorLocation === floorLocationName);
                        const transactionLine = transaction.value.getTransactionLineByItemId(dto.itemId);
                        let estimatedQty = 0;

                        if (transactionLine) {
                            estimatedQty = transactionLine.estimatedQuantity;
                        }

                        if (foundLine >= 0) {
                            sortedLines[foundLine].putAwayQtys++;
                        } else {
                            const newLine: ItemTableData = {
                                item: foundItem,
                                floorLocation: floorLocationName,
                                putAwayQtys: 1,
                                estimatedQty,
                                scannedTags: getCurrentTagsForItem(dto.itemId, dto.floorLocationId),
                            };

                            sortedLines.push(newLine);
                        }
                    });
                    return mapTransLinesToTableData(sortedLines);
                }
                return [];
            },
        );

        function addTag(trackedItem: TrackedItem): void {
            state.loading = true;

            // Fixes a scoping issue with Sentry
            const { selectedPutAwayItems } = state;

            if (selectedPutAwayItems.length === 0 || trackedItem.itemId === selectedPutAwayItems[0].itemId) {
                if (!selectedPutAwayItems.some((x) => x.barcode === trackedItem.barcode) && !putAwayItems.value.some((x) => x.trackedItemId === trackedItem.id)) {
                    state.selectedPutAwayItems.push(trackedItem);
                } else {
                    notification.showWarning(getTranslation('core.validation.itemIsAlreadyAdded', trackedItem.barcode));
                }
            } else {
                notification.showError(getTranslation('core.validation.scannedTagsMustBeSameItem'));
            }

            state.loading = false;
        }

        const tagHandler = new FloortrakReceivingTagScanHandler(transaction, tags, addTag);

        const fields: Array<BTableField<ItemTableData & { action: string }>> = [
            {
                key: 'item',
                width: '150px',
                label: getTitleCaseTranslation('core.domain.item'),
            },
            {
                key: 'floorLocation',
                width: '75px',
                label: getTitleCaseTranslation('core.common.floorLocation'),
            },
            {
                key: 'estimatedQty',
                width: '50px',
                label: getTitleCaseTranslation('core.domain.estimatedQuantity'),
            },
            {
                key: 'putAwayQtys',
                width: '75px',
                label: getTitleCaseTranslation('core.common.putAway'),
            },
            {
                key: 'scannedTags',
                width: '75px',
                label: getTitleCaseTranslation('core.common.tags'),
            },
        ];

        async function searchFloorLocationsAndItems(scannerInput: Array<string>) {
            state.loading = true;

            const foundFloorLocation = state.allFloorLocations.find((fl) => fl.name === scannerInput[0]);
            if (foundFloorLocation) {
                state.selectedFloorLocation = foundFloorLocation;
            } else {
                const trackedItem = await trackedItemService.getTrackedItemByBarcode(scannerInput[0]);
                if (trackedItem) {
                    const existingLine = transaction.value.getTransactionLineByItem(trackedItem.item);
                    if (existingLine) {
                        // We should only put away items that exist in TransactionLines
                        // This is for TRIVIUM WAREHOUSE ONLY and WILL need to be updated in the future
                        // e.g receiving packaging - in the future we need to be able to put away anything
                        await tagHandler.performSearchManagedWarehouse(scannerInput);
                    } else {
                        // TODO: get rid of this when we have more customers using this page
                        notification.showError(getTranslation('core.validation.itemNotOnTransaction'));
                    }
                } else {
                    // The scan was neither a valid tracked item nor a valid floor location
                    notification.showError(getTranslation('core.validation.notABarcodeOrFloorLocation', scannerInput[0].valueOf()));
                }
            }

            state.loading = false;
        }

        function closeSearch() {
            state.search = null;
            state.showSearch = false;
            if (transaction.value.transactionLines.length === 1) {
                state.transactionLineItem = transaction.value.transactionLines[0].item.displayName;
            }
        }

        function postSearchSuccess(shipment: Shipment) {
            state.shipment = shipment;
            closeSearch();
        }

        const { fetchReceivingShipment } = useReceivingSearch(postSearchSuccess);

        async function searchExisting() {
            state.loading = true;
            if (state.search) {
                state.searching = true;
                await fetchReceivingShipment(state.search, ReceivingAction.PUT_AWAY);
                state.putAwayItems = await receivingService.getWarehousePutAwayByTransactionId(transaction.value.id);
                state.putAwayItems.forEach(async (item) => {
                    state.transactionItems.push(await getItemById(item.itemId));
                    const trackedItem = await receivingService.getTrackedItemById(item.trackedItemId);
                    if (trackedItem.success) {
                        state.tags.push(trackedItem.data);
                    }
                });
                const allFloorLocations = await floorLocationService.getFloorLocationsForLocation(transaction.value.toLocation);
                if (allFloorLocations.success) {
                    state.allFloorLocations = allFloorLocations.floorLocations;
                }
                const occupiedFloorLocations = await floorLocationService.getOccupiedFloorLocationsForTransaction(transaction.value.id);
                if (occupiedFloorLocations.success) {
                    state.occupiedFloorLocations = occupiedFloorLocations.floorLocations;
                }
                state.searching = false;
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            tagListener.startListener();
            state.shipment.addTransaction(new Transaction());
            state.shipment.transactions[0].toLocation = new Location({
                id: 0,
                name: '',
            });

            state.loading = true;

            if (!forkliftCertified) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationMissing'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            } else if (!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationExpired'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }

            if (props.transactionNumber) {
                state.showSearch = false;
                state.search = parseInt(props.transactionNumber, 10);
                await searchExisting();
                if (!state.shipment?.id) {
                    state.showSearch = true;
                }
            }

            state.loading = false;
        });

        onBeforeUnmount(() => tagListener.stopListener());

        async function removeTag(tag: TrackedItem) {
            state.loading = true;

            const dtoToDelete = state.putAwayItems.find((dto) => dto.trackedItemId === tag.id);
            if (dtoToDelete) {
                const response = await receivingService.removeTrackedItemFromTransaction(dtoToDelete);
                if (response) {
                    let stateTagIndex = state.tags.findIndex((x) => x.id === tag.id);
                    state.tags.splice(stateTagIndex, 1);
                    stateTagIndex = state.putAwayItems.findIndex((x) => x.trackedItemId === tag.id);
                    state.putAwayItems.splice(stateTagIndex, 1);
                }
            }

            state.loading = false;
        }

        async function putAway() {
            state.loading = true;

            const itemAlreadyAdded = state.putAwayItems.findIndex((putAwayItem) => state.selectedPutAwayItems.some((selectedItem) => putAwayItem.trackedItemId === selectedItem.id));
            if (state.selectedFloorLocation && state.selectedPutAwayItems.length > 0 && itemAlreadyAdded < 0) {
                // This is all valid floor locations, vacant and shared item - refreshed from the database
                // We can statically reference the first element of the put away item array because they should all be the same item type
                const sharedFloorLocations = await floorLocationService.getSharedLocationsForTransactionAndItem(transaction.value.id, state.selectedPutAwayItems[0].itemId);
                const occupiedFloorLocations = await floorLocationService.getOccupiedFloorLocationsForTransaction(transaction.value.id);
                if (occupiedFloorLocations.success) {
                    state.occupiedFloorLocations = occupiedFloorLocations.floorLocations;
                }
                state.vacantFloorLocations = state.allFloorLocations.filter((vfl) => !state.occupiedFloorLocations.some((ofl) => ofl.name === vfl.name));
                const validFloorLocations = sharedFloorLocations.floorLocations.concat(state.vacantFloorLocations);

                // Fixes a scoping issue with Sentry
                const selectedFloorLocationName = state.selectedFloorLocation!.name;

                let proceed = true;

                if (!validFloorLocations.some((fl) => fl.name === selectedFloorLocationName)) {
                    // This is hit when the selected floor location is invalid, probably because there is another item type in the floor location
                    proceed = await confirm({
                        title: getTranslation('core.common.areYouSure'),
                        message: getTranslation('core.validation.floorLocationMayHaveOtherItems'),
                        vHtml: true,
                    });
                }

                if (proceed) {
                    const putAwayPayload: Array<WarehousePutAwayDTO> = [];

                    state.selectedPutAwayItems.forEach((trackedItem) => {
                        const itemToAdd = {
                            locationId: transaction.value.toLocationId,
                            itemId: trackedItem.item.id,
                            trackedItemId: trackedItem.id,
                            floorLocationId: state.selectedFloorLocation!.id,
                            inboundTransactionId: transaction.value.id,
                        };

                        putAwayPayload.push(itemToAdd);
                    });

                    const response = await receivingService.submitWarehousePutAway(putAwayPayload);

                    if (response.success) {
                        putAwayPayload.forEach(async (dto) => {
                            state.tags.push((await receivingService.getTrackedItemById(dto.trackedItemId)).data);
                            state.putAwayItems.push(dto);
                            state.transactionItems.push(await getItemById(dto.itemId));
                        });
                        state.selectedFloorLocation = undefined;
                        state.selectedPutAwayItems = [];
                    }
                }
            }

            if (itemAlreadyAdded >= 0) {
                notification.showError(getTranslation('core.validation.itemIsAlreadyAdded', state.selectedPutAwayItems[itemAlreadyAdded].barcode));

                state.selectedFloorLocation = undefined;
                state.selectedPutAwayItems = [];
            }

            state.loading = false;
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        async function onCloseout() {
            // Hydrate the transactionLines
            const tran = await transactionService.getTransactionById(transaction.value.id);

            if (tran.success) {
                let complete = true;
                tran.transaction.transactionLines.forEach((line) => {
                    if (line.actualQuantity !== line.estimatedQuantity) {
                        complete = false;
                    }
                });

                if (complete) {
                    const confirmResponse = await confirm({
                        title: getTranslation('core.common.areYouSure'),
                        message: getTranslation('core.common.proceedToCloseout'),
                        vHtml: true,
                    });

                    if (confirmResponse) {
                        await router.push({
                            name: FloorTrakRouteTypes.RECEIVING.CLOSEOUT,
                            params: { transactionNumber: transaction.value.id },
                        });
                    }
                } else {
                    notification.showError(getTranslation('core.validation.estimatedQtyActualQtyMatch'));
                }
            }
        }

        async function printInboundReceiptAndGoHome() {
            const confirmResponse = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.confirmSave'),
                vHtml: true,
            });

            if (confirmResponse) {
                state.loading = true;
                setLoading(true);
                await receivingService.getTransactionReceipt(transaction.value.id);
                setLoading(false);
                state.loading = false;

                await router.push({
                    name: FloorTrakRouteTypes.HOME,
                    params: { transactionNumber: transaction.value.id },
                });
            }
        }

        function cancelSafetyInspection() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        async function toggleLocationSearchModal() {
            // Start by clearing out shared locations in case the item changed
            state.sharedFloorLocations = [];
            if (state.selectedPutAwayItems.length > 0) {
                // Grab the records from the database to hydrate the shared locations
                const dbSharedFloorLocations = await floorLocationService.getSharedLocationsForTransactionAndItem(transaction.value.id, state.selectedPutAwayItems[0].itemId);
                state.sharedFloorLocations = dbSharedFloorLocations.floorLocations;
            }
            // Refresh the occupied floor locations
            const occupiedFloorLocations = await floorLocationService.getOccupiedFloorLocationsForTransaction(transaction.value.id);
            if (occupiedFloorLocations.success) {
                state.occupiedFloorLocations = occupiedFloorLocations.floorLocations;
            }
            state.vacantFloorLocations = state.allFloorLocations.filter((vfl) => !state.occupiedFloorLocations.some((ofl) => ofl.name === vfl.name));
            state.showLocationSearch = !state.showLocationSearch;
        }

        function selectFloorLocation(floorLocation: FloorLocation | undefined) {
            state.selectedFloorLocation = floorLocation;
        }

        return {
            state,
            transaction,
            fields,
            ItemType,
            tableData,
            searchExisting,
            putAway,
            escape,
            getCurrentTagsForItem,
            removeTag,
            onCloseout,
            getTranslation,
            getTitleCaseTranslation,
            printInboundReceiptAndGoHome,
            SafetyInspectionType,
            cancelSafetyInspection,
            getItemById,
            toggleLocationSearchModal,
            selectFloorLocation,
            pageTitle,
        };
    },
});

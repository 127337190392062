import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-141bef7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "lines-container"
}
const _hoisted_2 = {
  key: 0,
  class: "empty-table-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transaction_line_row = _resolveComponent("transaction-line-row")!
  const _component_faicon = _resolveComponent("faicon")!

  return (_ctx.lineList.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lineList, (line, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("div", {
              class: _normalizeClass({'striped': index & 1})
            }, [
              _createVNode(_component_transaction_line_row, {
                "line-is-readonly": _ctx.readonly,
                line: line,
                "modal-type": _ctx.state.modalType,
                "show-quantity-planned": _ctx.state.showQuantityPlanned,
                manual: _ctx.manuallyKeyInTags
              }, null, 8, ["line-is-readonly", "line", "modal-type", "show-quantity-planned", "manual"])
            ], 2)
          ]))
        }), 128)),
        (_ctx.lineList.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_faicon, { icon: "dolly-flatbed" }),
              _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.noContainers')), 1)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
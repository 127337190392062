import axios, { AxiosResponse } from 'axios';
import FloorLocation from '@/domain/FloorLocation';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class FloorLocationApiService {
    public async getFloorLocationsForLocation(locationId: number): Promise<DataAccessResponse<Array<FloorLocation>>> {
        const response: AxiosResponse<DataAccessResponse<Array<FloorLocation>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/FloorLocation/all/location/${locationId}`,
        });
        return new DataListAccessResponseClass<FloorLocation>(response, FloorLocation).response;
    }

    public async addFloorLocation(floorLocation: FloorLocation): Promise<DataAccessResponse<FloorLocation>> {
        const response: AxiosResponse<DataAccessResponse<FloorLocation>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/FloorLocation`,
            data: floorLocation,
        });

        return new DataAccessResponseClass<FloorLocation>(response).response;
    }

    public async updateFloorLocation(floorLocation: FloorLocation): Promise<DataAccessResponse<FloorLocation>> {
        const response: AxiosResponse<DataAccessResponse<FloorLocation>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/FloorLocation`,
            data: floorLocation,
        });

        return new DataAccessResponseClass<FloorLocation>(response).response;
    }

    public async getOccupiedFloorLocationsForTransaction(transactionId: number): Promise<DataAccessResponse<Array<FloorLocation>>> {
        const response: AxiosResponse<DataAccessResponse<Array<FloorLocation>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/FloorLocation/occupied/transaction/${transactionId}`,
        });
        return new DataListAccessResponseClass<FloorLocation>(response, FloorLocation).response;
    }

    public async getSharedLocationsForTransactionAndItem(transactionId: number, itemId: number): Promise<DataAccessResponse<Array<FloorLocation>>> {
        const response: AxiosResponse<DataAccessResponse<Array<FloorLocation>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/FloorLocation/shared/transaction/${transactionId}/${itemId}`,
        });
        return new DataListAccessResponseClass<FloorLocation>(response, FloorLocation).response;
    }
}

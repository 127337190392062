
import { defineComponent, PropType, reactive } from 'vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import { UseMaxTrailerCompute } from '@/composable/useMaxTrailerCompute';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import useDialogBox from "@/components/bootstrap-library/composables/useDialogBox";

type State = {
    showModal: boolean,
    modalTitle: string,
    modalBody: string,
    isShipNow: boolean,
    isDownloading: boolean
}

export default defineComponent({
    name: 'shipping-closeout-modal',
    components: {
        BSpinner,
        BButton,
        BModal,
        BDropdown,
        BDropdownItem,
    },
    props: {
        shipmentIsReadonly: {
            type: Boolean,
            default: false,
        },
        canShipNow: {
            type: Boolean,
            default: true,
        },
        maxTrailerComposable: {
            type: Object as PropType<UseMaxTrailerCompute>,
            required: true,
        },
        saveAndValidate: {
            type: Function as PropType<() => Promise<boolean>>,
            default: () => false,
        },
        isOverOrShortShipped: {
            type: Boolean,
            required: true,
        },
        trailerClosed: Boolean,
    },
    emits: ['saveShipment', 'moveToYard', 'shipNow', 'close', 'closeTrailer'],
    setup(props, context) {
        const state = reactive<State>({
            showModal: false,
            modalTitle: getTitleCaseTranslation('core.domain.shippingCloseout'),
            modalBody: '',
            isShipNow: false,
            isDownloading: false,
        });

        const { confirm } = useDialogBox();

        async function save() {
            if (await props.maxTrailerComposable.confirmOverCapacity()) {
                context.emit('saveShipment');
            }
        }

        async function moveToYard() {
            if (await props.maxTrailerComposable.confirmOverCapacity()) {
                if (await props.saveAndValidate()) {
                    state.modalTitle = getTitleCaseTranslation('core.button.moveToYard');
                    state.modalBody = getTranslation('core.validation.confirmMoveToYard');
                    state.showModal = true;
                    state.isShipNow = false;
                    state.isDownloading = false;
                }
            }
        }

        async function shipNow() {
            if (!props.isOverOrShortShipped
                || await confirm({
                    title: getTitleCaseTranslation('core.validation.overOrShortShippedHeader'),
                    message: getTranslation('core.validation.someTransactionLinesAreShortOrOvershipped'),
                    vHtml: true,
                })
            ) {
                if (await props.maxTrailerComposable.confirmOverCapacity()) {
                    if (await props.saveAndValidate()) {
                        state.modalTitle = getTitleCaseTranslation('core.button.shipNow');
                        state.modalBody = getTranslation('core.validation.confirmShipNow');
                        state.showModal = true;
                        state.isShipNow = true;
                        state.isDownloading = false;
                    }
                }
            }
        }

        function close(): void {
            state.showModal = false;
        }

        function closeTrailer() {
            context.emit('closeTrailer');
        }

        function onOk() {
            context.emit(state.isShipNow ? 'shipNow' : 'moveToYard');
        }

        function onCancel() {
            state.showModal = false;
        }

        function startDownload() {
            state.isDownloading = true;
        }

        return {
            save,
            close,
            moveToYard,
            shipNow,
            state,
            onOk,
            onCancel,
            startDownload,
            closeTrailer,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d336326"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "details-container" }
const _hoisted_3 = { class: "detail-col col-1" }
const _hoisted_4 = {
  id: "input-group-to",
  class: "shipping-header-form-group"
}
const _hoisted_5 = {
  id: "input-group-carrier",
  class: "shipping-header-form-group"
}
const _hoisted_6 = {
  id: "input-group-trailer",
  class: "shipping-header-form-group"
}
const _hoisted_7 = {
  id: "input-group-driver-name",
  class: "shipping-header-form-group"
}
const _hoisted_8 = { class: "shipping-header-form-group" }
const _hoisted_9 = { class: "shipping-header-form-group" }
const _hoisted_10 = { class: "detail-col col-2" }
const _hoisted_11 = { class: "shipping-header-form-group" }
const _hoisted_12 = {
  id: "input-group-carrier-ref",
  class: "shipping-header-form-group"
}
const _hoisted_13 = {
  id: "input-trailer-number",
  class: "shipping-header-form-group"
}
const _hoisted_14 = {
  id: "input-group-dock-door",
  class: "shipping-header-form-group"
}
const _hoisted_15 = {
  id: "input-group-seal",
  class: "shipping-header-form-group"
}
const _hoisted_16 = { class: "shipping-header-form-group" }
const _hoisted_17 = { class: "notes-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.transaction && _ctx.transaction.toLocation)
            ? (_openBlock(), _createBlock(_component_dropdown_autocomplete_single_select, {
                key: 0,
                modelValue: _ctx.transaction.toLocation.name ,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.transaction.toLocation.name ) = $event)),
                label: _ctx.getTitleCaseTranslation('core.common.to'),
                loading: _ctx.state.loading,
                disabled: _ctx.shipmentIsReadonly,
                data: _ctx.state.shipToLocations,
                "search-by": "name",
                "on-before-clear": _ctx.beforeClearToLocation,
                "empty-text": _ctx.getTranslation('core.common.noLocationsAssociated'),
                "display-name": "name",
                error: _ctx.toLocationErrorText,
                onOnSelect: _ctx.selectToLocation
              }, null, 8, ["modelValue", "label", "loading", "disabled", "data", "on-before-clear", "empty-text", "error", "onOnSelect"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            disabled: _ctx.shipmentIsReadonly,
            "model-value": _ctx.shipment.carrierName,
            error: _ctx.validationResult?.model.carrierId,
            data: _ctx.carriers,
            "search-by": "name",
            "empty-text": _ctx.getTranslation('core.common.noCarriersAvailable'),
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
            onOnSelect: _ctx.updateCarrier
          }, null, 8, ["loading", "disabled", "model-value", "error", "data", "empty-text", "label", "onOnSelect"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            disabled: _ctx.shipmentIsReadonly,
            "model-value": _ctx.shipment.trailerTypeName,
            error: _ctx.validationResult?.model.trailerType,
            data: _ctx.state.trailerTypes,
            "search-by": "name",
            "empty-text": _ctx.getTranslation('core.common.noTrailerTypesAvailable'),
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.trailerType'),
            onOnSelect: _ctx.updateTrailerType
          }, null, 8, ["loading", "disabled", "model-value", "error", "data", "empty-text", "label", "onOnSelect"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_b_form_input, {
            id: "input-driver-name",
            modelValue: _ctx.shipment.driver,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shipment.driver) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.driverName'),
            readonly: _ctx.shipmentIsReadonly
          }, null, 8, ["modelValue", "label", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_b_form_input, {
            id: "input-license-plate-number",
            modelValue: _ctx.shipment.licensePlateNumber,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shipment.licensePlateNumber) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.licensePlateNumberShort'),
            readonly: _ctx.shipmentIsReadonly,
            error: _ctx.validationResult?.model.licensePlateNumber
          }, null, 8, ["modelValue", "label", "readonly", "error"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.transaction)
            ? (_openBlock(), _createBlock(_component_b_form_input, {
                key: 0,
                modelValue: _ctx.transaction.partnerReferenceNumber,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transaction.partnerReferenceNumber) = $event)),
                readonly: _ctx.shipmentIsReadonly,
                label: _ctx.getTitleCaseTranslation('core.domain.partnerReferenceNumberShort'),
                error: _ctx.partnerRefErrorText
              }, null, 8, ["modelValue", "readonly", "label", "error"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_b_form_input, {
            id: "input-lot-number",
            modelValue: _ctx.shipment.lot,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.shipment.lot) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.lotNumberShort'),
            readonly: _ctx.shipmentIsReadonly,
            error: _ctx.validationResult?.model.lot
          }, null, 8, ["modelValue", "label", "readonly", "error"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_b_form_input, {
            id: "input-carrier-ref-num",
            modelValue: _ctx.shipment.carrierReferenceNumber,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shipment.carrierReferenceNumber) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.carrierReferenceNumberShort'),
            readonly: _ctx.shipmentIsReadonly,
            error: _ctx.validationResult?.model.carrierReferenceNumber
          }, null, 8, ["modelValue", "label", "readonly", "error"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_b_form_input, {
            id: "input-trailer",
            modelValue: _ctx.shipment.trailer,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.shipment.trailer) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.trailerNumberShort'),
            readonly: _ctx.shipmentIsReadonly,
            error: _ctx.validationResult?.model.trailer
          }, null, 8, ["modelValue", "label", "readonly", "error"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            "model-value": _ctx.shipment.dockName,
            data: _ctx.state.docks,
            "search-by": "name",
            "empty-text": _ctx.getTitleCaseTranslation('core.validation.noDocks'),
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.dock'),
            error: _ctx.validationResult?.model.dock,
            disabled: _ctx.shipmentIsReadonly,
            onOnSelect: _ctx.updateDock
          }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "error", "disabled", "onOnSelect"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_b_form_input, {
            id: "input-seal",
            modelValue: _ctx.shipment.seal,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.shipment.seal) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.sealNumberShort'),
            readonly: _ctx.shipmentIsReadonly
          }, null, 8, ["modelValue", "label", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          (_ctx.transaction)
            ? (_openBlock(), _createBlock(_component_b_form_input, {
                key: 0,
                modelValue: _ctx.transaction.customerPO,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.transaction.customerPO) = $event)),
                readonly: _ctx.shipmentIsReadonly,
                label: _ctx.getTranslation('core.domain.customerPO')
              }, null, 8, ["modelValue", "readonly", "label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_b_form_textarea, {
        id: "input-notes",
        modelValue: _ctx.shipment.deliveryNotes,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.shipment.deliveryNotes) = $event)),
        label: _ctx.getTitleCaseTranslation('core.domain.notes'),
        readonly: _ctx.shipmentIsReadonly,
        rows: "4",
        "max-length": 255
      }, null, 8, ["modelValue", "label", "readonly"])
    ])
  ]))
}
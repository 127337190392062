import Transaction from '@/domain/Transaction';
import TransactionLineDTO from '@/modules/floortrak/domain/jigsaw/dto/TransactionLineDTO';
import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';

export default class TransactionDTO extends DTOBuilder {
    public id!: number;

    public transactionNumber!: string;

    public toLocationId!: number;

    public fromLocationId!: number;

    public inventoryCategoryId!: number;

    public createdOn!: Date;

    public arrivedAt!: Date;

    public departedAt!: Date;

    public transactionLines: Array<TransactionLineDTO> = [];

    public dateRequired!: Date;

    public partnerReferenceNumber!: string;

    public timestamp!: string;

    public disputeImageStrings: Array<string> = [];

    public actualDepartureDate?: Date;

    public billToId?: number;

    public customerPO?: string;

    constructor(transaction: Transaction) {
        super();
        this.map<TransactionDTO, Transaction>(this, transaction);
        // Object.assign(this, transaction)
        // Object.keys(this).forEach(key=>transaction[key]=this[key]);
        if (transaction.toLocation) this.toLocationId = transaction.toLocation.id;
        if (transaction.fromLocation) this.fromLocationId = transaction.fromLocation.id;
        if (transaction.inventoryCategory) this.inventoryCategoryId = transaction.inventoryCategory.id;
        if (transaction.transactionLines) this.transactionLines = transaction.transactionLines.map((tl) => new TransactionLineDTO(tl));
    }
}

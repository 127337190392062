export type ValidationTranslation = {
    isRequired: string;
    cannotBeEmpty: string;
    oneOrMoreErrors: string;
    quantityPerItemRequired: string;
    effectiveFromRequired: String;
    effectiveFromMustBeLessThanEffectiveTo: string;
    effectiveToMustBeGreaterThanEffectiveFrom: string;
    partNumberRequired: string;
    fromLocationRequired: string;
    toLocationRequired: string;
    containerRequired: string;
    palletContainerRequired: string;
    containersPerPalletRequired: string;
    topCapContainerRequired: string;
    maximumTextLength50: string;
    sortStationRequired: string;
    unitLoadsOnlySortedToFinishedGoods: string;
    transactionNumberRequired: string;
    unitLoadContainsItemsNotInTransactionOk: string;
    transactionContainsEstimatesNoActuals: string;
    transactionContainsIncompleteSortedItems: string;
    movingToGlassWillUpdateExistingSortedItems: string;
    verifyAllPackingForLoadHasBeenSorted: string;
    revertLoadToInboundGoodsQuantitiesReEstablished: string;
    mustBeGreaterThanOpeningHours: string;
    mustBeOneHourLessThanClose: string;
    onlyHourIncrementsAreValid: string;
    carrierRequired: string;
    trailerTypeRequired: string;
    trailerRequired: string;
    dockRequired: string;
    toLocationMissingForStop: string;
    atLeastOneContainerMustBeAddedForStop: string;
    atLeastOneLineMustHaveActualQuantity: string;
    isNotValidTransactionId: string;
    unableToCreateNewShipment: string;
    unableToSaveShipment: string;
    toLocationMustBeSelected: string;
    success: string;
    error: string;
    formError: string;
    warning: string;
    userLocationMustBeSelectedBeforeStartingShipment: string;
    confirmMoveToYard: string;
    confirmShipNow: string;
    invalidTypeOf: string;
    invalid: string;
    noResults: string;
    inventorySuccessfullyMoved: string;
    errorRetrievingTrackedItemWithBarcode: string;
    updatedBarcodeTo: string;
    createdBarcode: string;
    barcodeMinimumFourCharacters: string;
    mustEnterMovementQuantity: string;
    alreadyScannedBarcode: string;
    combinedItemDoesNotHaveDefinitionCantUpdate: string;
    trackedItemWithTagWasScrapped: string;
    tagDoesNotExistInSystem: string;
    receiptAlreadyEstimated: string;
    sortedQuantityForCategoryCannotBeNegative: string;
    unitLoadCannotBeAddedGlassFound: string;
    quantityMustBeSpecified: string;
    changesSavedBeforePrintingAreYouSure: string;
    cannotAddMoreItemsToShipment: string;
    youHaveAnUnfinishedTransaction: string;
    resumeWhereLeftOff: string;
    errorLookingUpShipment: string;
    adminAndPlanningRequired: string;
    multipleShipFromCategoriesExistShipFromAandP: string;
    clearingDestinationWillClearContainersAndQuantities: string;
    pleaseFillOutAllFields: string;
    barcodeDoesNotMatchItem: string;
    couldNotFindBarcode: string;
    barcodeAlreadyAdded: string;
    qtyCannotBeLessThanTotalTagsForItem: string;
    cannotFindTable: string;
    exceededTrailerCapacityAdjustQuantity: string;
    exceededTrailerWeightAdjustQuantity: string;
    youHaveExceededThe: string;
    volumeCapacity: string;
    weightCapacity: string;
    allItemsContainOneOrMoreValidationErrorsThatNeedAddressing: string;
    allPFEPsContainOneOrMoreValidationErrorsThatNeedAddressing: string;
    barcodeMustNotExceed255Characters: string;
    companyLogoUrlIsNotValid: string;
    companyUrlIsNotValid: string;
    contactNameMustNotExceed100Characters: string;
    containersCountMustBeWithinAValidRange: string;
    creationDaysBeforeDueMustBeWithinAValidRange: string;
    cubicVolumeCannotBeZeroOrNegative: string;
    customerItemNumberIsTooLong: string;
    dateRequiredMustBeWithinPEMSettingsRange: string;
    descriptionShouldNotExceed20Characters: string;
    descriptionShouldNotExceed50Characters: string;
    displayNameMustNotExceed100Characters: string;
    dockNameMustNotExceed50Characters: string;
    dockTypeIsRequired: string;
    errorFetchingTransaction: string;
    emailAddressCannotContainWhitespace: string;
    emailMustBeAValidEmailAddress: string;
    emailMustNotExceed200Characters: string;
    errorGettingTrailerTypes: string;
    equipmentTypeRequired: string;
    frequencyIsRequired: string;
    heightCannotBeZeroOrNegative: string;
    inventoryAdjustmentThresholdMustBeAValidPercentage: string;
    inventoryCategoryMustBeDefined: string;
    isAlreadyAPreferredServiceCenter: string;
    itemIsAlreadyAdded: string;
    lengthCannotBeZeroOrNegative: string;
    locationNumberMustNotExceed25Characters: string;
    locationTypeIsRequired: string;
    makeMustNotExceed100Characters: string;
    modelMustNotExceed100Characters: string;
    mustDefineAtLeast2ComponentContainers: string;
    nameIsTooLong: string;
    nameMustNotExceed100Characters: string;
    nameMustNotExceed25Characters: string;
    numberMustNotExceed100Characters: string;
    openTimeCannotBeLaterThanCloseTime: string;
    palletQtyCannotBeZeroOrNegative: string;
    phoneMustBeAtLeast7Characters: string;
    phoneNumberMustNotExceed25Characters: string;
    pleaseEnterValidEmailAddressesSeparatedByCommas: string;
    pleaseProvideAValue: string;
    preferredServiceCenterCannotBeTheSameAsTheLocation: string;
    quantityCannotBeZeroOrNegative: string;
    receivedQuantitiesAreNotTheSameAsActualQuantities: string;
    roleMustHaveAtLeast1PermissionAssigned: string;
    secondPreferredServiceCenterCannotBeTheSameAsTheLocation: string;
    serialNumberMustNotExceed100Characters: string;
    shortNameIsTooLong: string;
    shortNameMustNotExceed25Characters: string;
    targetDaysOnHandMustBeGreaterThan0: string;
    taxIdMustNotExceed50Characters: string;
    thereAreMoreTagsScannedThanQuantitiesEntered: string;
    thirdPreferredServiceCenterCannotBeTheSameAsTheLocation: string;
    trailerLoadExceeded: string;
    noLocationsAssociated: string;
    noDocks: string;
    noCarriers: string;
    noTrailerTypes: string;
    transactionDoesNotHaveAnyItems: string;
    unableToFetchRoles: string;
    unableToMoveTransactionsToYard: string;
    unableToRetrieveLabelInformation: string;
    unableToUpdateRole: string;
    userMustHaveAtLeastOneLocationAssigned: string;
    userMustHaveAtLeastOneRoleAssigned: string;
    usernameCannotContainSpecialCharacters: string;
    usernameCannotContainWhitespace: string;
    usernameMustNotExceed50Characters: string;
    valueIsTooLong: string;
    weightCannotBeZeroOrNegative: string;
    whenTheCollapsibleCheckBoxIsCheckedCollapsedHeightIsRequired: string;
    widthCannotBeZeroOrNegative: string;
    youMustEnterActualQuantityForAtLeast1Item: string;
    youMustEnterActualQuantityForAtLeast1ItemInOrderToShip: string;
    youMustEnterPlannedQuantityForAtLeast1Item: string;
    youMustSelectAnInventoryCategory: string;
    minTrailerPercentMustBeBetweenOneAnd99: string;
    maxLTLDayRangeMustBeGreaterThanZero: string;
    unitLoadMustHaveOnePrimaryPlanningItem: string;
    itemOrPartNumberRequired: string;
    roleNameMustNotExceed35Characters: string;
    sortedTransactionIsComplete: string;
    equipmentInspectionIntervalMustBeBetween1And24: string;
    samAccountNameMustNotExceed100Characters: string;
    areYouSureResetPasswordFor: string;
    partNumberMustNotExceed100Characters: string;
    descriptionMustNotExceed250Characters: string;
    repairDescriptionShouldNotExceed250Characters: string;
    repairDoesNotHaveAnyRepairParts: string;
    repairNameShouldNotExceed100Characters: string;
    repairPartMustBeSelected: string;
    repairPartsMustBeUnique: string;
    serviceCenterAlreadyHasInventory: string;
    locationIsRequired: string;
    shipDateMustBeEarlierThanTheDockArrivalTime: string;
    yourLocationMustHaveAProcessedPutAwayPullFromLocation: string;
    maxLoadPerTimeslotMustBeGreaterThanZero: string;
    receiptHasNoLineItemsAreYouSure: string;
    fromItemAndToItemCannotBeTheSame: string;
    confirmRepairQuantity: string;
    confirmSortQuantity: string;
    floorLocationIsTooLong: string;
    barcodeDeleted: string;
    forkliftCertificationHeader: string;
    forkliftCertificationMissing: string;
    forkliftCertificationExpired: string;
    shippingPlannerMaxLoadsExceeded: string;
    shippingPlannerOutsideBusinessHours: string;
    partnerReferenceNumberIsTooLong: string;
    partnerReferenceNumber30CharactersError: string;
    carrierReferenceNumberIsTooLong: string;
    trailerNumberIsTooLong: string;
    trailerNumberIsRequired: string;
    reasonForBeingLateIsRequired: string;
    floorLocationDimensionsMustBeNumberXNumber: string;
    floorLocationNameMustNotExceed50Characters: string;
    cycleCountConfigurationLocationDataInvalid: string;
    cycleCountConfigurationItemDataInvalid: string;
    cycleCountConfigurationInventoryCategoryDataInvalid: string;
    cycleCountConfigurationSuccess: string;
    selectDaysOfWeek: string;
    cycleCountConfigurationUpdated: string;
    cycleCountReportCreated: string;
    tagNotOnPickList: string;
    areYouSureInShipment: string;
    pickPlanNotCompleted: string;
    notAllRequiredItems: string;
    itemNotOnTransaction: string;
    floorLocationMayHaveOtherItems: string;
    someTransactionLinesAreShortOrOvershipped: string;
    overOrShortShippedHeader: string;
    scannedTagsMustBeSameItem: string;
    estimatedQtyActualQtyMatch: string;
    notABarcodeOrFloorLocation: string;
};

const validationEn: ValidationTranslation = {
    isRequired: 'is required',
    cannotBeEmpty: 'cannot be empty',
    oneOrMoreErrors: 'there were 1 or more errors on the form',
    quantityPerItemRequired: 'quantity per item is required',
    effectiveFromRequired: 'effective from is required',
    effectiveFromMustBeLessThanEffectiveTo: 'effective from must be less than effective to',
    effectiveToMustBeGreaterThanEffectiveFrom: 'effective To must be greater than effective from',
    partNumberRequired: 'part number is required',
    fromLocationRequired: 'from location is required',
    toLocationRequired: 'to location is required',
    containerRequired: 'item is required',
    palletContainerRequired: 'pallet item number is required',
    containersPerPalletRequired: 'items per pallet is required',
    topCapContainerRequired: 'top cap item is required',
    maximumTextLength50: '50 character maximum',
    sortStationRequired: 'Sort Station is required',
    unitLoadsOnlySortedToFinishedGoods: 'Unit loads can only be sorted to finished goods',
    transactionNumberRequired: 'Transaction # is required',
    unitLoadContainsItemsNotInTransactionOk: 'This unit load contains these items that are not part of the transaction. Okay to add these items?',
    transactionContainsEstimatesNoActuals: 'Items with estimates have no dispositioned values.',
    transactionContainsIncompleteSortedItems: 'Items exist that have not been Load Complete.',
    movingToGlassWillUpdateExistingSortedItems: 'Moving to glass will update existing sorted items, and cannot be undone.',
    verifyAllPackingForLoadHasBeenSorted: 'Verify all packaging for this load has been sorted before completing.',
    revertLoadToInboundGoodsQuantitiesReEstablished: 'Revert load to Inbound Goods? Previously sorted quantities will be re-established.',
    mustBeGreaterThanOpeningHours: 'Must be greater than opening hours',
    mustBeOneHourLessThanClose: 'Must be 1 hour less than close time',
    onlyHourIncrementsAreValid: 'Only hour increments are valid',
    carrierRequired: 'Carrier is required',
    trailerTypeRequired: 'Trailer Type is required',
    trailerRequired: 'Trailer is required',
    dockRequired: 'Dock is required',
    toLocationMissingForStop: 'To Location missing for stop',
    atLeastOneContainerMustBeAddedForStop: 'At least one item must be added for stop',
    atLeastOneLineMustHaveActualQuantity: 'At least one line must have actual quantity',
    isNotValidTransactionId: 'is not a valid Transaction ID',
    unableToCreateNewShipment: 'Unable to create new Shipment',
    unableToSaveShipment: 'Unable to save Shipment',
    toLocationMustBeSelected: 'To Location must be selected',
    success: 'success',
    error: 'error',
    formError: 'form error',
    warning: 'warning',
    userLocationMustBeSelectedBeforeStartingShipment: 'User location must be selected before starting shipment',
    confirmMoveToYard: 'Are you sure you want to move to yard?',
    confirmShipNow: 'Are you sure you want to ship now?',
    invalidTypeOf: 'Invalid type of',
    invalid: 'invalid',
    noResults: 'no results',
    inventorySuccessfullyMoved: 'Inventory was successfully moved!',
    errorRetrievingTrackedItemWithBarcode: 'Tag {0} is not on the route',
    updatedBarcodeTo: 'Updated barcode to',
    createdBarcode: 'Created barcode',
    barcodeMinimumFourCharacters: 'Barcode must be at least 4 characters long',
    mustEnterMovementQuantity: 'Must enter movement quantity',
    alreadyScannedBarcode: 'Already scanned barcode',
    combinedItemDoesNotHaveDefinitionCantUpdate: "Combined item doesn't have a definition. You can't update inventory for this item until a definition has been created in master data",
    trackedItemWithTagWasScrapped: 'Tracked item with tag {0} was scrapped',
    tagDoesNotExistInSystem: 'Tag {0} does not exist in the system',
    receiptAlreadyEstimated: 'This receipt has already been estimated',
    sortedQuantityForCategoryCannotBeNegative: 'Total sorted quantity for a category cannot be negative',
    unitLoadCannotBeAddedGlassFound: 'Unit loads cannot be added when glass has been found',
    quantityMustBeSpecified: 'Quantity must be specified',
    changesSavedBeforePrintingAreYouSure: 'Changes need to be saved before printing. Are you sure?',
    cannotAddMoreItemsToShipment: 'Cannot add any more items to this Shipment',
    youHaveAnUnfinishedTransaction: 'You have an unfinished transaction',
    resumeWhereLeftOff: 'Would you like to resume where you left off?',
    errorLookingUpShipment: 'Error looking up shipment',
    adminAndPlanningRequired: 'Admin & Planning required!',
    multipleShipFromCategoriesExistShipFromAandP: 'Multiple Ship From categories exist. Initiate shipment from A&P',
    clearingDestinationWillClearContainersAndQuantities: 'Clearing Destination Location will clear all items and quantities for this Transaction',
    pleaseFillOutAllFields: 'Please fill out all fields',
    barcodeDoesNotMatchItem: 'Barcode does not match item',
    couldNotFindBarcode: 'Could not find barcode',
    barcodeAlreadyAdded: 'Barcode already added',
    qtyCannotBeLessThanTotalTagsForItem: 'QTY ({0}) cannot be less than total tags scanned for this item',
    cannotFindTable: 'Cannot find table',
    exceededTrailerCapacityAdjustQuantity: 'Exceeded max trailer capacity. Please make quantity adjustments',
    exceededTrailerWeightAdjustQuantity: 'Exceeded max trailer weight capacity. Please make quantity adjustments',
    youHaveExceededThe: 'You have exceeded the',
    volumeCapacity: 'volume capacity',
    weightCapacity: 'weight capacity',
    allItemsContainOneOrMoreValidationErrorsThatNeedAddressing: 'All Items contain one or more validation errors that need addressing.',
    allPFEPsContainOneOrMoreValidationErrorsThatNeedAddressing: 'All PFEPs contain one or more validation errors that need addressing.',
    barcodeMustNotExceed255Characters: 'Barcode must not exceed 255 characters',
    companyLogoUrlIsNotValid: 'Company Logo Url is not valid',
    companyUrlIsNotValid: 'Company Url is not valid',
    contactNameMustNotExceed100Characters: 'Contact name must not exceed 100 characters',
    containersCountMustBeWithinAValidRange: 'Items Count must be within a valid range [1 - 50]',
    creationDaysBeforeDueMustBeWithinAValidRange: 'Creation days Before Due must be within a valid range [0 - 31]',
    cubicVolumeCannotBeZeroOrNegative: 'Cubic volume cannot be zero or negative',
    customerItemNumberIsTooLong: 'Customer Item Number is too long',
    dateRequiredMustBeWithinPEMSettingsRange: 'Date Required must be within {0} - {1} days',
    descriptionShouldNotExceed20Characters: 'Description should not exceed 20 characters',
    descriptionShouldNotExceed50Characters: 'Description should not exceed 50 characters',
    displayNameMustNotExceed100Characters: 'Display name must not exceed 100 characters',
    dockNameMustNotExceed50Characters: 'Dock name must not exceed 50 characters',
    dockTypeIsRequired: 'Dock Type is required',
    errorFetchingTransaction: 'Error fetching transaction',
    errorGettingTrailerTypes: 'Error getting trailer types',
    equipmentTypeRequired: 'Equipment Type is required',
    emailAddressCannotContainWhitespace: 'Email address cannot contain whitespace',
    emailMustBeAValidEmailAddress: 'Email must be a valid email address',
    emailMustNotExceed200Characters: 'Email must not exceed 200 characters',
    frequencyIsRequired: 'Frequency is required',
    heightCannotBeZeroOrNegative: 'Height cannot be zero or negative',
    itemIsAlreadyAdded: 'Item "{0}" is already added',
    inventoryAdjustmentThresholdMustBeAValidPercentage: 'Inventory adjustment threshold must be a valid percentage [0 - 100]',
    inventoryCategoryMustBeDefined: 'Inventory Category must be defined',
    isAlreadyAPreferredServiceCenter: '{0} is already a preferred service center',
    lengthCannotBeZeroOrNegative: 'Length cannot be zero or negative',
    locationNumberMustNotExceed25Characters: 'Location number must not exceed 25 characters',
    locationTypeIsRequired: 'Location Type is required',
    makeMustNotExceed100Characters: 'Make must not exceed 100 characters',
    modelMustNotExceed100Characters: 'Model must not exceed 100 characters',
    mustDefineAtLeast2ComponentContainers: 'Must define at least 2 component items',
    nameIsTooLong: 'Name is too long',
    nameMustNotExceed100Characters: 'Name must not exceed 100 characters',
    nameMustNotExceed25Characters: 'Name must not exceed 25 characters',
    numberMustNotExceed100Characters: 'Number must not exceed 100 characters',
    openTimeCannotBeLaterThanCloseTime: 'Open Time cannot be later than Close Time',
    palletQtyCannotBeZeroOrNegative: 'Pallet Quantity cannot be zero or negative',
    phoneMustBeAtLeast7Characters: 'Phone must be at least 7 characters',
    phoneNumberMustNotExceed25Characters: 'Phone number must not exceed 25 characters',
    pleaseEnterValidEmailAddressesSeparatedByCommas: 'Please enter valid email address(es) separated by commas',
    pleaseProvideAValue: 'please provide a value',
    preferredServiceCenterCannotBeTheSameAsTheLocation: 'Preferred Service Center cannot be the same as the location',
    quantityCannotBeZeroOrNegative: 'Quantity cannot be zero or negative',
    receivedQuantitiesAreNotTheSameAsActualQuantities: 'Received quantities are not the same as actual quantities',
    roleMustHaveAtLeast1PermissionAssigned: 'Role must have at least 1 Permission assigned',
    secondPreferredServiceCenterCannotBeTheSameAsTheLocation: 'Second Preferred Service Center cannot be the same as the location',
    serialNumberMustNotExceed100Characters: 'Serial Number must not exceed 100 characters',
    shortNameIsTooLong: 'Short Name is too long',
    shortNameMustNotExceed25Characters: 'Short name must not exceed 25 characters',
    targetDaysOnHandMustBeGreaterThan0: 'Target Days On Hand must be greater than 0',
    taxIdMustNotExceed50Characters: 'Tax Id must not exceed 50 characters',
    thereAreMoreTagsScannedThanQuantitiesEntered: 'There are more tags scanned than quantities entered',
    thirdPreferredServiceCenterCannotBeTheSameAsTheLocation: 'Third Preferred Service Center cannot be the same as the location',
    trailerLoadExceeded: 'trailer load exceeded',
    noLocationsAssociated: 'No locations associated',
    noDocks: 'no docks',
    noCarriers: 'no carriers',
    noTrailerTypes: 'no trailer types',
    transactionDoesNotHaveAnyItems: 'Transaction does not have any items',
    unableToFetchRoles: 'unable to fetch roles',
    unableToMoveTransactionsToYard: 'Unable to move transactions to yard',
    unableToRetrieveLabelInformation: 'Unable to retrieve label information',
    unableToUpdateRole: 'unable to update role',
    userMustHaveAtLeastOneLocationAssigned: 'User must have at least one Location assigned',
    userMustHaveAtLeastOneRoleAssigned: 'User must have at least one Role assigned',
    usernameCannotContainSpecialCharacters: 'Username cannot contain special characters',
    usernameCannotContainWhitespace: 'Username cannot contain whitespace',
    usernameMustNotExceed50Characters: 'Username must not exceed 50 characters',
    valueIsTooLong: 'Value is too long',
    weightCannotBeZeroOrNegative: 'Weight cannot be zero or negative',
    whenTheCollapsibleCheckBoxIsCheckedCollapsedHeightIsRequired: "When the 'Collapsible' check box is checked, 'Collapsed Height' is required",
    widthCannotBeZeroOrNegative: 'Width cannot be zero or negative',
    youMustEnterActualQuantityForAtLeast1Item: 'You must enter actual quantity for at least 1 item',
    youMustEnterActualQuantityForAtLeast1ItemInOrderToShip: 'You must enter actual quantity for at least 1 item in order to ship',
    youMustEnterPlannedQuantityForAtLeast1Item: 'You must enter planned quantity for at least 1 item',
    youMustSelectAnInventoryCategory: 'You must select an inventory category.',
    minTrailerPercentMustBeBetweenOneAnd99: 'Min Trailer % must be between 1 and 99',
    maxLTLDayRangeMustBeGreaterThanZero: 'Max LTL Day Range must be greater than 0',
    unitLoadMustHaveOnePrimaryPlanningItem: 'Unit load must have one primary planning item',
    itemOrPartNumberRequired: 'Item number or part number required',
    roleNameMustNotExceed35Characters: 'Role name must not exceed 35 characters and can only be alphanumeric',
    sortedTransactionIsComplete: 'Transaction is already complete',
    equipmentInspectionIntervalMustBeBetween1And24: 'Equipment Inspection Interval must be between 1 and 24',
    samAccountNameMustNotExceed100Characters: 'SAM Account Name must not exceed 100 characters',
    areYouSureResetPasswordFor: 'Are you sure you want to reset the password for:',
    partNumberMustNotExceed100Characters: 'Part number must not exceed 100 characters',
    descriptionMustNotExceed250Characters: 'Description must not exceed 250 characters',
    repairDescriptionShouldNotExceed250Characters: 'Repair description should not exceed 250 characters',
    repairDoesNotHaveAnyRepairParts: 'Repair does not have any repair parts',
    repairNameShouldNotExceed100Characters: 'Repair name should not exceed 100 characters',
    repairPartMustBeSelected: 'Repair part must be selected',
    repairPartsMustBeUnique: 'Repair parts must be unique',
    serviceCenterAlreadyHasInventory: 'Repart part inventory already exists for this location. Choose a different location',
    locationIsRequired: 'Location is required',
    shipDateMustBeEarlierThanTheDockArrivalTime: 'Ship date must be earlier than the dock arrival time',
    yourLocationMustHaveAProcessedPutAwayPullFromLocation: 'Your location must have a processed put away "pull from" location',
    maxLoadPerTimeslotMustBeGreaterThanZero: 'Max Load Per Timeslot must be greater than 0',
    receiptHasNoLineItemsAreYouSure: 'This receipt has no line items. Are you sure you want to proceed to close this out?',
    fromItemAndToItemCannotBeTheSame: 'From Item and To Item cannot be the same',
    confirmRepairQuantity: 'Repair quantity is more than 1000 pieces',
    confirmSortQuantity: 'Quantity is more than 1000 pieces',
    floorLocationIsTooLong: 'Floor location is too long',
    barcodeDeleted: 'Pallet flagged for Hold.  Cannot be shipped',
    forkliftCertificationHeader: 'Forklift Certification Status Issue',
    forkliftCertificationMissing: 'Access prohibited without forklift certification. Please see the manager.',
    forkliftCertificationExpired: 'Your forklift certification has expired. Please see the manager.',
    shippingPlannerMaxLoadsExceeded: 'The {0} timeslot is at capacity.<br/>Are you sure you want to make this reservation?',
    shippingPlannerOutsideBusinessHours: 'The selection is outside of normal business operating hours.<br/>Are you sure you want to make this reservation?',
    partnerReferenceNumberIsTooLong: 'Partner Reference Number is too long',
    partnerReferenceNumber30CharactersError: 'Partner Reference Number cannot exceed 30 characters',
    carrierReferenceNumberIsTooLong: 'Carrier Reference Number is too long',
    trailerNumberIsTooLong: 'Trailer Number is too long',
    trailerNumberIsRequired: 'Trailer Number is required',
    reasonForBeingLateIsRequired: 'Late reason is required',
    floorLocationDimensionsMustBeNumberXNumber: 'Floor Location dimensions must be formatted as `[number]x[number]`',
    floorLocationNameMustNotExceed50Characters: 'Floor Location name must not exceed 50 characters',
    cycleCountConfigurationLocationDataInvalid: 'Location Data is Invalid',
    cycleCountConfigurationItemDataInvalid: 'Item Data is Invalid',
    cycleCountConfigurationInventoryCategoryDataInvalid: 'Inventory Category Data is Invalid',
    cycleCountConfigurationSuccess: 'Successfully created Cycle Count Configuration',
    selectDaysOfWeek: 'You must select the days of the week',
    cycleCountConfigurationUpdated: 'Successfully updated Cycle Count Configuration',
    cycleCountReportCreated: 'Cycle count report was created',
    tagNotOnPickList: 'Tag Not on Pick List',
    areYouSureInShipment: 'Are you sure you want to include it in this shipment?',
    pickPlanNotCompleted: 'Pick Plan not completed',
    notAllRequiredItems: 'Not all required items have been picked. Are you sure you wish to continue?',
    itemNotOnTransaction: 'Item is not found on the Transaction. Please contact a supervisor.',
    floorLocationMayHaveOtherItems: 'Floor location may have other item types.',
    overOrShortShippedHeader: 'Short or Overshipped Items',
    someTransactionLinesAreShortOrOvershipped: 'Some transaction lines are either short or over the planned amount. Do you wish to continue?',
    scannedTagsMustBeSameItem: 'Scanned tags must be of the same item type',
    estimatedQtyActualQtyMatch: 'Scanned quantity and Estimated quantity must match',
    notABarcodeOrFloorLocation: '{0} is not a valid barcode or floor location name',
};

const validationEs: ValidationTranslation = {
    isRequired: 'requerido',
    cannotBeEmpty: 'no puede estar vacío',
    oneOrMoreErrors: 'hubo 1 o mas errores en el formulario',
    quantityPerItemRequired: 'se requiere cantidad por artículo',
    effectiveFromRequired: 'fecha efectiva desde requerido',
    effectiveFromMustBeLessThanEffectiveTo: 'efectivo desde debe ser menor que efectivo hasta',
    effectiveToMustBeGreaterThanEffectiveFrom: 'efectivo hasta debe ser mayor que efectivo desde',
    partNumberRequired: 'número de parte requerido',
    fromLocationRequired: 'desde la ubicación es obligatorio',
    toLocationRequired: 'a la ubicación es obligatorio',
    containerRequired: 'el articulo es requerido',
    palletContainerRequired: 'se requiere el número de artículo de la tarima',
    containersPerPalletRequired: 'se requieren artículos por tarima',
    topCapContainerRequired: 'se requiere el elemento de la tapa superior',
    maximumTextLength50: 'máximo de 50 caracteres',
    sortStationRequired: 'La estación de clasificación es obligatoria',
    unitLoadsOnlySortedToFinishedGoods: 'Las unidades de carga solo se pueden clasificar en productos terminados',
    transactionNumberRequired: 'Se requiere el número de transacción',
    unitLoadContainsItemsNotInTransactionOk: 'Esta unidad de carga contiene estos artículos que no forman parte de la transacción. ¿Está bien agregar estos elementos?',
    transactionContainsEstimatesNoActuals: 'Los artículos con estimaciones no tienen valores dispuestos.',
    transactionContainsIncompleteSortedItems: 'Existen elementos que no han sido Carga completa.',
    movingToGlassWillUpdateExistingSortedItems: 'Pasar al cristal actualizará los elementos ordenados existentes y no se puede deshacer',
    verifyAllPackingForLoadHasBeenSorted: 'Verifique que todo el embalaje para esta carga se haya clasificado antes de completar.',
    revertLoadToInboundGoodsQuantitiesReEstablished: '¿Revertir la carga a mercancías entrantes? Se restablecerán las cantidades clasificadas previamente.',
    mustBeGreaterThanOpeningHours: 'Debe ser mayor que el horario de apertura',
    mustBeOneHourLessThanClose: 'Debe ser 1 hora menos que la hora de cierre',
    onlyHourIncrementsAreValid: 'Solo los incrementos de horas son válidos',
    carrierRequired: 'Se requiere transportista',
    trailerTypeRequired: 'Se requiere el tipo de remolque',
    trailerRequired: 'se requiere remolque',
    dockRequired: 'Se requiere muelle',
    toLocationMissingForStop: 'Falta la ubicación para la parada',
    atLeastOneContainerMustBeAddedForStop: 'Se debe agregar al menos un elemento para detener',
    atLeastOneLineMustHaveActualQuantity: 'Al menos una línea debe tener la cantidad actual',
    isNotValidTransactionId: 'no es un ID de transacción válido',
    unableToCreateNewShipment: 'No se puede crear un nuevo envío',
    unableToSaveShipment: 'No se puede guardar el envío',
    toLocationMustBeSelected: 'Se debe seleccionar A la ubicación',
    success: 'éxito',
    error: 'error',
    formError: 'error de formulario',
    warning: 'advertencia',
    userLocationMustBeSelectedBeforeStartingShipment: 'La ubicación del usuario debe seleccionarse antes de iniciar el envío',
    confirmMoveToYard: '¿Estás seguro de que quieres moverte al patio?',
    confirmShipNow: '¿Estás seguro de que quieres enviar ahora?',
    invalidTypeOf: 'Tipo inválido de',
    invalid: 'inválido',
    noResults: 'no hay resultados',
    inventorySuccessfullyMoved: '¡El inventario se movió con éxito!',
    errorRetrievingTrackedItemWithBarcode: 'La etiqueta {0} no está en la ruta',
    updatedBarcodeTo: 'Código de barras actualizado a',
    createdBarcode: 'código de barras creado',
    barcodeMinimumFourCharacters: 'El código de barras debe tener al menos 4 caracteres',
    mustEnterMovementQuantity: 'Debe ingresar la cantidad de movimiento',
    alreadyScannedBarcode: 'Código de barras ya escaneado',
    // eslint-disable-next-line max-len
    combinedItemDoesNotHaveDefinitionCantUpdate:
        'Elemento combinado no tiene una definición. No puede actualizar el inventario de este artículo hasta que se haya creado una definición en los datos maestros.',
    trackedItemWithTagWasScrapped: 'El artículo rastreado con la etiqueta {0} fue desechado',
    tagDoesNotExistInSystem: 'La etiqueta {0} no existe en el sistema',
    receiptAlreadyEstimated: 'Este recibo ya ha sido estimado',
    sortedQuantityForCategoryCannotBeNegative: 'La cantidad total clasificada para una categoría no puede ser negativa',
    unitLoadCannotBeAddedGlassFound: 'Las unidades de carga no se pueden agregar cuando se ha encontrado vidrio',
    quantityMustBeSpecified: 'Se debe especificar la cantidad',
    changesSavedBeforePrintingAreYouSure: 'Los cambios deben guardarse antes de imprimir. ¿Está seguro?',
    cannotAddMoreItemsToShipment: 'No se pueden agregar más artículos a este envío',
    youHaveAnUnfinishedTransaction: 'Tienes una transacción sin terminar',
    resumeWhereLeftOff: '¿Te gustaría continuar donde lo dejaste?',
    errorLookingUpShipment: 'Error al buscar el envío',
    adminAndPlanningRequired: 'Administración Y Planeación requerido!',
    multipleShipFromCategoriesExistShipFromAandP: 'Existen varias categorías Enviar desde. Iniciar envío desde A&P',
    clearingDestinationWillClearContainersAndQuantities: 'Al borrar la ubicación de destino, se borrarán todos los artículos y cantidades de esta transacción.',
    pleaseFillOutAllFields: 'Por favor rellene todos los campos',
    barcodeDoesNotMatchItem: 'El código de barras no coincide con el artículo',
    couldNotFindBarcode: 'No se pudo encontrar el código de barras',
    barcodeAlreadyAdded: 'Código de barras ya agregado',
    qtyCannotBeLessThanTotalTagsForItem: 'QTY ({0}) no puede ser menor que el total de etiquetas escaneadas para este artículo',
    cannotFindTable: 'No se puede encontrar la mesa',
    exceededTrailerCapacityAdjustQuantity: 'Se ha excedido la capacidad máxima del trailer. Por favor, haga ajustes en las cantidades',
    exceededTrailerWeightAdjustQuantity: 'Se ha excedido la capacidad máxima de peso del remolque. Por favor, haga ajustes de cantidad.',
    youHaveExceededThe: 'Has superado el/la',
    volumeCapacity: 'capacidad de volumen',
    weightCapacity: 'capacidad de peso',
    allItemsContainOneOrMoreValidationErrorsThatNeedAddressing: 'Todos los elementos contienen uno o más errores de validación que deben revisarse.',
    allPFEPsContainOneOrMoreValidationErrorsThatNeedAddressing: 'Todos los PFEP contienen uno o más errores de validación que deben revisarse.',
    barcodeMustNotExceed255Characters: 'el código de barras no debe exceder los 255 caracteres',
    companyLogoUrlIsNotValid: 'La URL del logotipo de la empresa no es válida',
    companyUrlIsNotValid: 'La URL de la empresa no es válida',
    contactNameMustNotExceed100Characters: 'El nombre del contacto no debe exceder los 100 caracteres',
    containersCountMustBeWithinAValidRange: 'El recuento de elementos debe estar dentro de un rango válido [1 - 50]',
    creationDaysBeforeDueMustBeWithinAValidRange: 'Los días de creación antes del vencimiento deben estar dentro de un rango válido [0 - 31]',
    cubicVolumeCannotBeZeroOrNegative: 'El volumen cúbico no puede ser cero o negativo',
    customerItemNumberIsTooLong: 'El número de artículo del cliente es demasiado largo',
    dateRequiredMustBeWithinPEMSettingsRange: 'La fecha requerida debe estar dentro de {0} a {1} días',
    descriptionShouldNotExceed20Characters: 'La descripción no debe exceder los 20 caracteres',
    descriptionShouldNotExceed50Characters: 'La descripción no debe exceder los 50 caracteres',
    displayNameMustNotExceed100Characters: 'El nombre para mostrar no debe exceder los 100 caracteres',
    dockNameMustNotExceed50Characters: 'El nombre del muelle no debe exceder los 50 caracteres',
    dockTypeIsRequired: 'El tipo de muelle es obligatorio',
    emailAddressCannotContainWhitespace: 'La dirección de correo electrónico no puede contener espacios en blanco',
    emailMustBeAValidEmailAddress: 'El correo electrónico debe ser una dirección de correo electrónico válida',
    emailMustNotExceed200Characters: 'El correo electrónico no debe exceder los 200 caracteres',
    errorFetchingTransaction: 'Error al obtener la transacción',
    errorGettingTrailerTypes: 'Error al obtener tipos de tráiler',
    equipmentTypeRequired: 'El tipo de equipo es obligatorio',
    frequencyIsRequired: 'Se requiere frecuencia',
    heightCannotBeZeroOrNegative: 'La altura no puede ser cero o negativa',
    inventoryAdjustmentThresholdMustBeAValidPercentage: 'El rango de ajuste de inventario debe ser un porcentaje válido [0 - 100]',
    inventoryCategoryMustBeDefined: 'Se debe definir la categoría de inventario',
    isAlreadyAPreferredServiceCenter: '{0} ya es un centro de servicio preferido',
    itemIsAlreadyAdded: 'El artículo "{0}" ya está agregado',
    lengthCannotBeZeroOrNegative: 'La longitud no puede ser cero o negativa',
    locationNumberMustNotExceed25Characters: 'El número de ubicación no debe exceder los 25 caracteres',
    locationTypeIsRequired: 'El tipo de ubicación es obligatorio',
    makeMustNotExceed100Characters: 'La marca no debe exceder los 100 caracteres',
    modelMustNotExceed100Characters: 'El modelo no debe superar los 100 caracteres',
    mustDefineAtLeast2ComponentContainers: 'Debe definir al menos 2 componentes',
    nameIsTooLong: 'El nombre es demasiado largo',
    nameMustNotExceed100Characters: 'El nombre no debe exceder los 100 caracteres',
    nameMustNotExceed25Characters: 'El nombre no debe exceder los 25 caracteres',
    numberMustNotExceed100Characters: 'El número no debe exceder los 100 caracteres',
    openTimeCannotBeLaterThanCloseTime: 'La hora de apertura no puede ser posterior a la hora de cierre',
    palletQtyCannotBeZeroOrNegative: 'La cantidad de tarimas no puede ser cero o negativa',
    phoneMustBeAtLeast7Characters: 'El teléfono debe tener al menos 7 caracteres',
    phoneNumberMustNotExceed25Characters: 'El número de teléfono no debe exceder los 25 caracteres',
    pleaseEnterValidEmailAddressesSeparatedByCommas: 'Ingrese direcciones de correo electrónico válidas separadas por comas',
    pleaseProvideAValue: 'por favor proporcione un valor',
    preferredServiceCenterCannotBeTheSameAsTheLocation: 'El centro de servicio preferido no puede ser el mismo que la ubicación',
    quantityCannotBeZeroOrNegative: 'La cantidad no puede ser cero o negativa',
    receivedQuantitiesAreNotTheSameAsActualQuantities: 'Las cantidades recibidas no son las mismas que las cantidades reales',
    roleMustHaveAtLeast1PermissionAssigned: 'El rol debe tener al menos 1 permiso asignado',
    secondPreferredServiceCenterCannotBeTheSameAsTheLocation: 'El segundo centro de servicio preferido no puede ser el mismo que la ubicación',
    serialNumberMustNotExceed100Characters: 'el número de serie no debe exceder los 100 caracteres',
    shortNameIsTooLong: 'El nombre corto es demasiado largo',
    shortNameMustNotExceed25Characters: 'El nombre corto no debe exceder los 25 caracteres',
    targetDaysOnHandMustBeGreaterThan0: 'El objetivo de días disponibles debe ser mayor que 0',
    taxIdMustNotExceed50Characters: 'El número de identificación fiscal no debe exceder los 50 caracteres',
    thereAreMoreTagsScannedThanQuantitiesEntered: 'Hay más etiquetas escaneadas que cantidades ingresadas',
    thirdPreferredServiceCenterCannotBeTheSameAsTheLocation: 'El tercer centro de servicio preferido no puede ser el mismo que la ubicación',
    trailerLoadExceeded: 'carga del remolque excedida',
    noLocationsAssociated: 'No hay ubicaciones asociadas',
    noDocks: 'sin muelles',
    noCarriers: 'sin transportistas',
    noTrailerTypes: 'No hay tipos de remolque',
    transactionDoesNotHaveAnyItems: 'La transacción no tiene ningún artículo',
    unableToFetchRoles: 'no se pueden buscar roles',
    unableToMoveTransactionsToYard: 'No se pueden mover transacciones al depósito',
    unableToRetrieveLabelInformation: 'No se puede recuperar la información de la etiqueta',
    unableToUpdateRole: 'no se puede actualizar el rol',
    userMustHaveAtLeastOneLocationAssigned: 'El usuario debe tener al menos una ubicación asignada',
    userMustHaveAtLeastOneRoleAssigned: 'El usuario debe tener al menos un Rol asignado',
    usernameCannotContainSpecialCharacters: 'El nombre de usuario no puede contener caracteres especiales',
    usernameCannotContainWhitespace: 'El nombre de usuario no puede contener espacios en blanco',
    usernameMustNotExceed50Characters: 'El nombre de usuario no debe exceder los 50 caracteres',
    valueIsTooLong: 'El valor es demasiado largo',
    weightCannotBeZeroOrNegative: 'El peso no puede ser cero o negativo',
    whenTheCollapsibleCheckBoxIsCheckedCollapsedHeightIsRequired: "Cuando la casilla de verificación 'Colapsable' está marcada, se requiere 'Altura colapsada'",
    widthCannotBeZeroOrNegative: 'El ancho no puede ser cero o negativo',
    youMustEnterActualQuantityForAtLeast1Item: 'Debe ingresar la cantidad real para al menos 1 artículo',
    youMustEnterActualQuantityForAtLeast1ItemInOrderToShip: 'Debe ingresar la cantidad real de al menos 1 artículo para enviar',
    youMustEnterPlannedQuantityForAtLeast1Item: 'Debe ingresar la cantidad planificada para al menos 1 artículo',
    youMustSelectAnInventoryCategory: 'Debe seleccionar una categoría de inventario.',
    minTrailerPercentMustBeBetweenOneAnd99: 'El porcentaje mínimo de tráiler debe estar entre 1 y 99',
    maxLTLDayRangeMustBeGreaterThanZero: 'El intervalo máximo de días LTL debe ser mayor que 0',
    unitLoadMustHaveOnePrimaryPlanningItem: 'La unidad de carga debe tener un elemento de planificación principal',
    itemOrPartNumberRequired: 'Artículo o número de parte requerido',
    roleNameMustNotExceed35Characters: 'El nombre del rol no debe exceder los 35 caracteres y solo puede ser alfanumérico',
    sortedTransactionIsComplete: 'La transacción ya está completa',
    equipmentInspectionIntervalMustBeBetween1And24: 'El intervalo de inspección del equipo debe estar entre 1 y 24',
    samAccountNameMustNotExceed100Characters: 'SAM El nombre de la cuenta no debe exceder los 100 caracteres',
    areYouSureResetPasswordFor: '¿Está seguro de que desea restablecer la contraseña de:',
    partNumberMustNotExceed100Characters: 'El número de pieza no debe exceder los 100 caracteres',
    descriptionMustNotExceed250Characters: 'La descripción no debe exceder los 250 caracteres',
    repairDescriptionShouldNotExceed250Characters: 'La descripción de la reparación no debe exceder los 250 caracteres',
    repairDoesNotHaveAnyRepairParts: 'La reparación no tiene piezas de repuesto',
    repairNameShouldNotExceed100Characters: 'El nombre de la reparación no debe exceder los 100 caracteres',
    repairPartMustBeSelected: 'Se debe seleccionar la pieza de reparación',
    repairPartsMustBeUnique: 'Las piezas de reparación deben ser únicas',
    serviceCenterAlreadyHasInventory: 'El inventario de repuestos ya existe para esta ubicación. Elija una ubicación diferente',
    locationIsRequired: 'Se requiere ubicación',
    shipDateMustBeEarlierThanTheDockArrivalTime: 'La fecha de envío debe ser anterior a la hora de llegada al muelle',
    yourLocationMustHaveAProcessedPutAwayPullFromLocation: 'Su ubicación debe tener una ubicación de "extracción" procesada y guardada',
    maxLoadPerTimeslotMustBeGreaterThanZero: 'La Carga Máxima Por Intervalo De Tiempo debe ser mayor que 0',
    receiptHasNoLineItemsAreYouSure: 'Este entrada no tiene partidas individuales. ¿Está seguro de que desea continuar para cerrar esto?',
    fromItemAndToItemCannotBeTheSame: 'El artículo "Desde" y el artículo "Hasta" no pueden ser iguales',
    confirmRepairQuantity: 'La cantidad de reparación es más de 1000 piezas',
    confirmSortQuantity: 'La cantidad es más de 1000 piezas',
    floorLocationIsTooLong: 'La ubicación del piso es demasiado larga',
    barcodeDeleted: 'Palet marcado para Retener. No se puede enviar',
    forkliftCertificationHeader: 'Problema de estado de certificación de montacargas',
    forkliftCertificationMissing: 'Acceso prohibido sin certificación de montacargas. Por favor consulte al gerente.',
    forkliftCertificationExpired: 'Su certificación de montacargas ha caducado. Por favor consulte al gerente.',
    shippingPlannerMaxLoadsExceeded: 'El intervalo de las {0} está a capacidad.<br/>¿Estás seguro de que quieres hacer esta reserva?',
    shippingPlannerOutsideBusinessHours: 'La selección está fuera del horario comercial normal.<br/>¿Estás seguro de que quieres hacer esta reserva?',
    partnerReferenceNumberIsTooLong: 'El número de referencia del socio es demasiado largo',
    partnerReferenceNumber30CharactersError: 'El número de referencia del socio no puede exceder los 30 caracteres',
    carrierReferenceNumberIsTooLong: 'El número de referencia del transportista es demasiado largo',
    trailerNumberIsTooLong: 'El número de remolque es demasiado largo',
    trailerNumberIsRequired: 'Se requiere número de remolque',
    reasonForBeingLateIsRequired: 'Se requiere motivo de retraso',
    floorLocationDimensionsMustBeNumberXNumber: 'Las dimensiones de la ubicación del piso deben tener el formato `[número]x[número]`',
    floorLocationNameMustNotExceed50Characters: 'El nombre de la Ubicación del piso no debe exceder los 50 caracteres',
    cycleCountConfigurationLocationDataInvalid: 'Los datos de ubicación no son válidos',
    cycleCountConfigurationItemDataInvalid: 'Los datos del artículo no son válidos',
    cycleCountConfigurationInventoryCategoryDataInvalid: 'Los datos de la categoría de inventario no son válidos',
    cycleCountConfigurationSuccess: 'Configuración de recuento de ciclos creada correctamente',
    selectDaysOfWeek: 'Debes seleccionar los días de la semana',
    cycleCountConfigurationUpdated: 'Configuración de recuento de ciclos actualizada correctamente',
    cycleCountReportCreated: 'Se creó un informe de recuento de ciclos',
    tagNotOnPickList: 'Etiqueta no en la lista de selección',
    areYouSureInShipment: 'Estás seguro de que quieres incluirlo en este envío?',
    pickPlanNotCompleted: 'Plan de selección no completado',
    notAllRequiredItems: 'No se han enviado todos los artículos necesarios. ¿Estás seguro de que deseas continuar?',
    itemNotOnTransaction: 'El artículo no se encuentra en la transacción. Por favor contacte a un supervisor.',
    floorLocationMayHaveOtherItems: 'La ubicación del piso puede tener otros tipos de elementos.',
    someTransactionLinesAreShortOrOvershipped: 'Algunas líneas de transacción son insuficientes o superan el monto planificado. ¿Desea continuar?',
    overOrShortShippedHeader: 'Artículos con envío insuficiente o excesivo',
    scannedTagsMustBeSameItem: 'Las etiquetas escaneadas deben ser del mismo tipo de elemento',
    estimatedQtyActualQtyMatch: 'La cantidad escaneada y la cantidad estimada deben coincidir',
    notABarcodeOrFloorLocation: '{0} no es un código de barras o nombre de ubicación de piso válido',
};

export { validationEn, validationEs };

import TransactionLine, { TransactionLineInit } from '@/domain/TransactionLine';
import TransactionDTO from '@/modules/floortrak/domain/jigsaw/dto/TransactionDTO';
import Location from '@/domain/Location';
import Item from '@/domain/Item';
import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import { normalizeDate, normalizeDateIfNull } from '@/functions/date';
import TrackedItem from './TrackedItem';
import TransactionLineDetail from './TransactionLineDetail';
import ImageUpload, { UploadImageEvent } from '@/domain/ImageUpload';
import DisputeTransactionDTO from '@/dtos/DisputeTransactionDTO';
import InventoryCategory from '@/domain/InventoryCategory';
import TransactionAbstractShare from './TransactionAbstractShare';

export interface ITransaction {
    id: number;
    transactionNumber: string;
    toLocation: Location;
    fromLocation: Location;
    transactionStatusDescription: string;
    hasShipped: boolean;
    createdOn: Date;
    arrivedAt: Date;
    plannedDepartureDate: Date;
    actualDepartureDate: Date;
    dateRequired: Date;
    status: TransactionStatus;
    transactionLines: Array<TransactionLine>;
    disputeNotes: string;
    disputeImageFileNames?: Array<string>;
    plannedDeliveryDate: Date;
    partnerReferenceNumber: string;
    timestamp: string;
    isShippingToDifferentCountry: boolean;
    inventoryCategory?: InventoryCategory;
    isEstimateFinalized: boolean;
    originalTransactionId?: number;
    billToId?: number;
    customerPO?: string;
}

export default class Transaction extends TransactionAbstractShare implements ITransaction {
    public id: number = 0;

    public transactionNumber!: string;

    public toLocation: Location = new Location();

    public fromLocation: Location = new Location();

    public createdOn!: Date;

    public arrivedAt!: Date;

    public transactionStatusDescription!: string;

    public plannedDepartureDate!: Date;

    public actualDepartureDate!: Date;

    public dateRequired!: Date;

    public status!: TransactionStatus;

    public disputeNotes!: string;

    public plannedDeliveryDate!: Date;

    public partnerReferenceNumber!: string;

    public timestamp!: string;

    public isShippingToDifferentCountry!: boolean;

    public inventoryCategory?: InventoryCategory;

    private _truckCardInventoryCategoryDescription: string = '';

    private _transactionLines: Array<TransactionLine> = [];

    public disputeImages: Array<ImageUpload> = [];

    private maxDisputeImages = 3;

    public isEstimateFinalized: boolean = false;

    public originalTransactionId?: number;

    public billToId?: number;

    public customerPO?: string;

    constructor(init?: ITransaction) {
        super();
        this.createdOn = new Date();

        if (init) {
            this.id = init.id;
            this.transactionNumber = init.transactionNumber;
            this.toLocation = new Location(init.toLocation);
            this.fromLocation = new Location(init.fromLocation) || new Location();
            this.createdOn = normalizeDate(init.createdOn);
            this.arrivedAt = normalizeDate(init.arrivedAt);
            this.plannedDepartureDate = normalizeDate(init.plannedDepartureDate);
            this.actualDepartureDate = normalizeDate(init.actualDepartureDate);
            this.status = init.status;
            this.dateRequired = normalizeDate(init.dateRequired);
            this.transactionStatusDescription = Formatter.GetFriendlyValue(init.status);
            this.disputeNotes = init.disputeNotes;
            this.plannedDeliveryDate = normalizeDate(init.plannedDeliveryDate);
            this.partnerReferenceNumber = init.partnerReferenceNumber;
            this.timestamp = init.timestamp;
            this.isShippingToDifferentCountry = init.isShippingToDifferentCountry;
            this.inventoryCategory = new InventoryCategory(init.inventoryCategory);
            this._truckCardInventoryCategoryDescription = this.inventoryCategory?.description;
            this.isEstimateFinalized = init.isEstimateFinalized;
            this.originalTransactionId = init.originalTransactionId;
            this.billToId = init.billToId;
            this.customerPO = init.customerPO;
            if (init.transactionLines && init.transactionLines.length > 0) {
                init.transactionLines.forEach((line) => {
                    line.item = new Item(line.item);
                    this.addTransactionLine(line);
                });
            }

            if (init.disputeImageFileNames && Array.isArray(init.disputeImageFileNames)) {
                this.disputeImages = init.disputeImageFileNames.map((fileName) => ImageUpload.CreateImageUrl(fileName));
            }
        } else {
            this.status = TransactionStatus.ORDERED;
        }
    }

    get shipDate(): Date | null {
        if (this.status >= TransactionStatus.IN_TRANSIT) {
            return this.actualDepartureDate;
        }
        return this.plannedDepartureDate;
    }

    // Currently no way in the UI to set actualDepartureDate. It's set server-side when "ship now" is clicked
    set shipDate(newDate: Date | null) {
        if (this.status >= TransactionStatus.IN_TRANSIT) {
            this.actualDepartureDate = normalizeDateIfNull(newDate);
        } else {
            this.plannedDepartureDate = normalizeDateIfNull(newDate);
        }
    }

    get inventoryCategoryDescription(): string | undefined {
        return this.inventoryCategory?.description;
    }

    get inventoryCategoryId(): number {
        return this.inventoryCategory?.id || 0;
    }

    get isInventoryCategorySet(): boolean {
        return this.inventoryCategoryId > 0;
    }

    private addTransactionLine(init: TransactionLineInit) {
        if (init.requestedQuantity || init.plannedQuantity || init.actualQuantity !== undefined || init.receivedQuantity !== undefined) {
            const newLine = new TransactionLine(init);
            this._transactionLines.push(newLine);
        }
    }

    public clearTransactionLines() {
        this._transactionLines = [];
    }

    public getInventoryCategoryDescriptionForTruckCard(): string | undefined {
        return this._truckCardInventoryCategoryDescription;
    }

    public setInventoryCategoryDescriptionForTruckCard(description: string | undefined) {
        this._truckCardInventoryCategoryDescription = description || '';
    }

    public addEmptyLine(item: Item) {
        const existingLine = this.getTransactionLineByItem(item);
        if (!existingLine) {
            const newLine = new TransactionLine({ item });
            this._transactionLines.push(newLine);
        }
    }

    public addActualItemQty(item: Item, quantity: number) {
        const existingLine = this.getTransactionLineByItem(item);
        if (existingLine) {
            if (existingLine.actualQuantity) {
                existingLine.actualQuantity += quantity || 0;
            } else {
                existingLine.actualQuantity = quantity || 0;
            }
        } else {
            this.addTransactionLine({
                item,
                actualQuantity: quantity,
            });
        }
    }

    public setActualItemQty(item: Item, quantity: number, unitLoadParentId?: number) {
        const existingLine = this.getTransactionLineByUnitLoadGroupId(item.id, unitLoadParentId);
        if (existingLine) {
            existingLine.actualQuantity = quantity || 0;
        } else {
            this.addTransactionLine({
                item,
                actualQuantity: quantity,
            });
        }
    }

    public addEstimatedItemQty(item: Item, quantity: number) {
        const existingLine = this.getTransactionLineByItem(item);
        if (existingLine) {
            existingLine.estimatedQuantity += quantity;
        } else {
            this.addEmptyLine(item);
            this.setEstimatedItemQty(item, quantity);
        }
    }

    public setEstimatedItemQty(item: Item, quantity: number) {
        const existingLine = this.getTransactionLineByItem(item);
        if (existingLine) {
            existingLine.estimatedQuantity = quantity ?? 0;
        } else {
            this.addTransactionLine({
                item,
                estimatedQuantity: quantity,
            });
        }
    }

    public setEstimateAdjustment(item: Item, quantity: number) {
        const existingLine = this.getTransactionLineByItem(item);
        if (existingLine) {
            existingLine.estimateAdjustment = quantity ?? 0;
        } else {
            this.addTransactionLine({
                item,
                estimatedQuantity: quantity,
            });
        }
    }

    public setReceivedQuantity(item: Item, quantity: number, unitLoadParentId?: number) {
        const existingLine = this.getTransactionLineByUnitLoadGroupId(item.id, unitLoadParentId);
        if (existingLine) {
            existingLine.receivedQuantity = quantity;
        } else {
            this.addTransactionLine({
                item,
                receivedQuantity: quantity,
            });
        }
    }

    public addPlannedItemQty(item: Item, quantity: number) {
        const existingLine = this.getTransactionLineByItem(item);
        if (existingLine) {
            existingLine.plannedQuantity += quantity;
        } else {
            this.addTransactionLine({
                item,
                plannedQuantity: quantity,
            });
        }
    }

    public setPlannedItemQty(item: Item, quantity: number) {
        const existingLine = this.getTransactionLineByItem(item);
        if (existingLine) {
            existingLine.plannedQuantity = quantity;
        } else {
            this.addTransactionLine({
                item,
                plannedQuantity: quantity,
            });
        }
    }

    public setRequestedItemQty(item: Item, qty: number) {
        const existingLine = this.getTransactionLineByItem(item);
        if (existingLine) {
            existingLine.requestedQuantity = qty;
        } else {
            this.addTransactionLine({
                item,
                requestedQuantity: qty,
            });
        }
    }

    public setSupplierShippingItemQty(item: Item, quantity: number) {
        if (this.status < TransactionStatus.PICKED) {
            this.setPlannedItemQty(item, quantity);
        } else {
            this.setActualItemQty(item, quantity);
        }
    }

    public addTrackedItem(trackedItem: TrackedItem) {
        const existingLine = this.getTransactionLineByItemId(trackedItem.item.id);
        const newLineDetail = new TransactionLineDetail(trackedItem);
        if (existingLine) {
            existingLine.addLineDetail(newLineDetail);
        } else {
            const init: TransactionLineInit = {
                item: trackedItem.item,
                actualQuantity: 0,
            };
            const newLine = new TransactionLine(init);
            newLine.addLineDetail(newLineDetail);
            this._transactionLines.unshift(newLine);
        }
    }

    public clearActualQuantityForReceiving() {
        // Sets actualQuantity to 0 for Blind Receipt or Receive Existing
        this._transactionLines.forEach((line) => {
            line.clearActualQuantity();
        });
    }

    public removeTrackedItem(trackedItem: TrackedItem) {
        const line = this.getTransactionLineByItemId(trackedItem.item.id);
        if (line) {
            const lineDetail = line.getLineDetailByBarcode(trackedItem.barcode);
            if (lineDetail) {
                line.removeLineDetail(lineDetail);
            }
        }
    }

    public removeTrackedItemManagedWarehouse(trackedItem: TrackedItem) {
        const line = this.getTransactionLineByItemId(trackedItem.item.id);
        if (line) {
            const lineDetail = line.getLineDetailByBarcode(trackedItem.barcode);
            if (lineDetail) {
                line.removeLineDetailByTrackedItemId(lineDetail);
            }
        }
    }

    public getTagsByItem(item: Item): Array<string> {
        const line = this._transactionLines.find((l) => l.item.id === item.id);
        if (line) {
            return line.tagList;
        }
        return [];
    }

    public getTrackedItemsByItem(item: Item): Array<TrackedItem> {
        const line = this._transactionLines.find((l) => l.item.id === item.id);
        if (line) {
            return line.transactionLineDetails.map((d) => d.trackedItem);
        }
        return [];
    }

    get tagList(): Array<string> {
        return this._transactionLines.map((l) => l.tagList).flat();
    }

    public getTransactionLineByItem(item: Item): TransactionLine | undefined {
        return this._transactionLines.find((line) => line.item.id === item.id);
    }

    public getTransactionLineByItemId(itemId: number): TransactionLine | undefined {
        return this._transactionLines.find((line) => line.item.id === itemId);
    }

    private getTransactionLineByUnitLoadGroupId(itemId: number, unitLoadParentId?: number): TransactionLine | undefined {
        if (unitLoadParentId) {
            return this._transactionLines.find((line) => line.item.id === itemId && line.unitLoadParentId === unitLoadParentId);
        }

        return this._transactionLines.find((line) => line.item.id === itemId && !line.unitLoadParentId);
    }

    public removeTransactionLineByItem(item: Item) {
        const lineToRemove = this._transactionLines.find((tl) => tl.item.id === item.id);
        if (lineToRemove) {
            this._transactionLines.splice(this._transactionLines.indexOf(lineToRemove), 1);
        }
    }

    public transferExistingItemQtyFromActualToPlanned() {
        this._transactionLines.forEach((line) => {
            if (!line.plannedQuantity) {
                line.plannedQuantity = line.actualQuantity;
            }
        });
    }

    get transactionLines(): Array<TransactionLine> {
        return this._transactionLines;
    }

    get totalItems(): number {
        let qty = 0;
        this._transactionLines.forEach((line) => {
            qty += line.actualQuantity || 0;
        });
        return qty;
    }

    get totalEstimatedItems(): number {
        let qty = 0;
        this._transactionLines.forEach((line) => {
            qty += line.estimatedQuantity || 0;
        });
        return qty;
    }

    get totalPlannedItems(): number {
        return this._transactionLines.reduce((previousValue, currentLine) => previousValue + (currentLine.plannedQuantity || 0), 0);
    }

    get fromLocationId(): number {
        if (this.fromLocation && this.fromLocation.id) {
            return this.fromLocation.id;
        }
        return 0;
    }

    get toLocationId(): number {
        if (this.toLocation && this.toLocation.id) {
            return this.toLocation.id;
        }
        return 0;
    }

    get totalCubicVolume(): number {
        let vol = 0;
        this.transactionLines.forEach((line) => {
            if (line.actualQuantity && line.item.containerVolume) {
                vol += line.actualQuantity * (line.item.containerVolume / 1728);
            }
        });
        return Math.ceil(vol);
    }

    get hasShipped(): boolean {
        return this.status >= TransactionStatus.IN_TRANSIT;
    }

    private canConfirmOrDispute(userLocation: Location): boolean {
        const acceptableTransactionStatuses = [TransactionStatus.IN_TRANSIT, TransactionStatus.DELIVERY_CONFIRMED];

        return acceptableTransactionStatuses.includes(this.status) && this.getDirection(userLocation) === 'inbound';
    }

    public canDispute(userLocation: Location): boolean {
        return this.canConfirmOrDispute(userLocation);
    }

    public canConfirm(userLocation: Location): boolean {
        return this.canConfirmOrDispute(userLocation);
    }

    public canAdminEditLocations(userLocation: Location): { to: boolean; from: boolean } {
        if (this.status === TransactionStatus.ORDERED && userLocation.availableShipToLocations.find((l) => l.id === this.toLocation.id)) {
            return { to: true, from: true };
        }
        if (this.status === TransactionStatus.PLANNED && userLocation.availableShipToLocations.find((l) => l.id === this.toLocation.id)) {
            return { to: false, from: true };
        }
        if (this.getDirection(userLocation) === 'inbound') {
            return { to: false, from: true };
        }
        if (this.getDirection(userLocation) === 'outbound' && this.status < TransactionStatus.HELD) {
            return { to: true, from: true };
        }
        if (!this.fromLocation.id) {
            return { to: false, from: true };
        }
        return { to: false, from: false };
    }

    public canShipNow(userLocation: Location): boolean {
        if (!this.isExisting) return true;
        if (this.getDirection(userLocation)) {
            return this.status <= TransactionStatus.HELD && !this.hasShipped;
        }
        return false;
    }

    get isExisting(): boolean {
        return this.id > 0;
    }

    public addDisputeImage(value: UploadImageEvent): boolean {
        if (this.canUploadDisputeImage) {
            const image = ImageUpload.CreateBase64Image(value);

            if (!this.disputeImages.some((img) => img.base64String === image.base64String)) {
                this.disputeImages.push(image);
                return true;
            }
        }
        return false;
    }

    get canUploadDisputeImage(): boolean {
        const disputableTransactionStatuses = [TransactionStatus.IN_TRANSIT, TransactionStatus.DELIVERY_CONFIRMED];

        return disputableTransactionStatuses.includes(this.status) && this.disputeImages.length < this.maxDisputeImages;
    }

    public toDTO(): TransactionDTO {
        return new TransactionDTO(this);
    }

    public toDisputeDTO(): DisputeTransactionDTO {
        return new DisputeTransactionDTO(this);
    }
}

import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

import WarehousePutAwayDTO from '@/dtos/WarehousePutAwayDTO';
import ProductionPartLoadInventory from '@/domain/ProductionPartLoadInventory';
import TrackedItem from '@/domain/TrackedItem';
import ManagedWarehouseMovementDTO from '@/dtos/ManagedWarehouseMovementDTO';

export default class WarehouseReceivingApiService {
    public async warehousePutAway(dtos: Array<WarehousePutAwayDTO>): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/warehousereceiving/warehouse-put-away`,
            data: dtos,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getWarehousePutAwayByTransactionId(transactionId: number): Promise<DataAccessResponse<Array<WarehousePutAwayDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<WarehousePutAwayDTO>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/warehousereceiving/transaction/${transactionId}`,
        });

        return new DataListAccessResponseClass<WarehousePutAwayDTO>(response, WarehousePutAwayDTO).response;
    }

    public async getTrackedItemById(trackedItemId: number): Promise<DataAccessResponse<TrackedItem>> {
        const response: AxiosResponse<DataAccessResponse<TrackedItem>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/warehousereceiving/get-tracked-item/${trackedItemId}`,
        });

        return new DataAccessResponseClass<TrackedItem>(response, TrackedItem).response;
    }

    public async removeTrackedItemFromTransaction(dto: WarehousePutAwayDTO): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/warehousereceiving/warehouse-delete`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async managedWarehouseMovement(dto: ManagedWarehouseMovementDTO): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/warehousereceiving/warehouse-move`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
